<template>
    <div class="service-snt">
        <div class="container">
            <div class="service-snt-title">
                <h2>НАСТРОЙКА ВИРТУАЛЬНОГО СКЛАДА И ПОДГОТОВКА К СНТ</h2>
            </div>

            <ul >
                <div class="snt-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                    <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                    <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                </svg>
                <li class="list-snt">Консультация по работе с системой виртуальный склад на сайте ЭСФ и в 1С</li></div>
                <div class="snt-item">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                    </svg>
                <li>Настройка базы 1С для работы с ВС (включение функционала ВС в 1С).</li>
                </div>
                <div class="snt-item">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                    </svg>
                <li>Синхронизация 1С и ВС (создание складов, загрузка справочников ГСВС).</li>
                </div>
                <div class="snt-item">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                    </svg>
                <li>Проверка базы на корректность ведения учета по источникам происхождения (отсутствие минусовых остатков),
                    проверка кодов ТНВД в карточке номенклатуры.</li>
                </div>
               <div class="snt-item">
                   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                       <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                       <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                   </svg>
                <li>Создание и отправка документов ввода начальных остатков на ВС</li>
               </div>
                <div class="snt-item">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                    </svg>
                <li>Консультация по работе с документами ВС в 1С</li>
                </div>
                <div class="snt-item">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                    </svg>
                <li>Данная настройка требует текущего договора сопровождения 1С:ИТС.</li>
                </div>
            </ul>
            <div class="snt-price">
                Стоимость: 30 000 тенге
            </div>
            <div class="button-content">
                <myButton @click="showDialog" type="submit"> Заказать настройку СНТ</myButton>
            </div>

            <Popup v-model:show="dialogVisible">
                <Form
                        @emailSend="sendForm"
                >
                </Form>
            </Popup>
        </div>
    </div>
</template>

<script>
    import myButton from "@/components/UI/myButton";
    import Popup from "@/components/UI/Popup";
    import Form from "@/components/UI/Form";
    export default {
    components:{
        myButton,Popup,Form
    },
        data() {
            return{
                dialogVisible: false,
                callDates: [{
                    name: '',
                    email: '',
                    phone: '',
                    admin_email: '',
                    form_subject: '',
                    project_name: ''
                }],
            }

        },

        methods: {
            showDialog() {
                this.dialogVisible = true
            },
            sendForm(callData){
                this.callDates.push(callData);
                this.dialogVisible = false;
            },

        },
    }
</script>

<style scoped lang="scss">
.service-snt{
    background: darken(#0a53be,15%);

    display: flex;
    position: relative;
    padding-top: 90px;
    padding-bottom: 80px;
}
    .service-snt-title{
        display: flex;
        position: relative;
        color: white;
        padding-bottom: 40px;
        font-size: 44px;
    }
    ul{
        padding-left: 0;
        color: white;
    }
    .snt-item{
        display: flex;
        width: 60%;
    }
    li{
        list-style-type: none;
        margin-bottom: 15px;
        color: white;
        margin-left: 10px;
    }
    .snt-price{
        padding-top: 20px;
        display: flex;
        font-size: 24px;
        color: white;
    }
svg{
    height: 24px;
    min-width: 24px;
    display: flex;
    position: relative;
}
.button-content{
    display: flex;

    position: relative;
    padding-top: 40px;
}
</style>