<template>
    <div>
        <div class="modal" v-if="show" @click.stop="hidePopup">
            <div class="modal-background" >
                <div class="modal-content" @click.stop>
                    <slot></slot>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

    import myButton from "@/components/UI/myButton";
    import axios from 'axios';
    export default {
        data() {
            return {

            }
        },
        props: {
            show: {
                type: Boolean,
                default: false
            }
        },
        components: {
            myButton
        },


        methods: {
            hidePopup() {
                this.$emit('update:show', false)

            },


        }
    }
</script>

<style scoped lang="scss">
    .modal, .modal-background{
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
    }
    .modal{
        display: flex;


    }
    .modal-background{
        z-index: 40;
        background: rgba(46, 46, 46, 0.7);
        text-align: center;
        align-items: center;
        display: flex;


    }
    .modal-content{
        display: flex;
        z-index: 50;
        width: 320px;
        margin: 0 auto;
    }

</style>