<template>
    <div class="review">
        <div class="container">
            <div class="review-title">
                <h2>Отзывы наших клиентов</h2>
            </div>


            <div class="review-content">
                <div class="review-item">
                <div class="review-image">
                    <img src="../assets/s-1.jpg" alt="">
                </div>
                <div class="review-name">
                    <h5>Автушко Светлана</h5>
                    <p>Мы обратились в компанию «Art Manipulated Business»,
                        не единожды выполняла работы согласно договора ИТС по обновлению и консультации.</p>
                </div>
            </div>

                <div class="review-item">
                    <div class="review-image">
                        <img src="../assets/3.jpg" alt="">
                    </div>
                    <div class="review-name">
                        <h5>Мунира Муталипова</h5>
                        <p>Мы обратились в компанию «Art Manipulated Business»,
                            для внедрения программного продукта Управление торговым предприятием.</p>
                    </div>
                </div>

                <div class="review-item">
                    <div class="review-image">
                        <img src="../assets/2.jpg" alt="">
                    </div>
                    <div class="review-name">
                        <h5>Айман Бежанова</h5>
                        <p>Мы обратились в компанию «Art Manipulated Business»,
                            Компания ТОО «Art Manipulated Business» лучший помощник в 1С. Быстро реагировали на наши заявки.</p>
                    </div>
                </div>

            </div>



        </div>

    </div>
</template>

<script>
    export default {

    }
</script>

<style  lang="scss">
.review{
    display: flex;
    position: relative;
    padding-bottom: 70px;
    padding-top: 80px;
}
.review-title{
    display: flex;
    @media(max-width: 756px) {
        text-align: center;
    }

}
.review-content{
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 60px;
    margin-bottom: 20px;
}
.review-item{
    display: flex;
    position: relative;
    width: calc(33% - 15px);
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 0px 15px 15px #efe5fd;
    padding-bottom: 40px;
    border: 2px solid #cccccc;
    @media (max-width: 992px){
        width: calc(50% - 10px);
    }
    @media (max-width: 576px){
        width: calc(100%);
        margin-top: 40px;
    }
}

.review-image{
    display: flex;
    margin-top: 60px;
    align-items: center;
    justify-content: center;

}
.review-image>img{
    border-radius: 50%;
    height: 200px;
}
.review-name{
    text-align: center;
    margin: 30px;
}
</style>