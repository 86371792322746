<template>

        <div :class="{'product-item': true}">
            <div class="product-image">
                <img  @click=""  :src="require('../assets/' + product.image)"
                      :alt="product.image"
                >
            </div>

            <div class="product-item-text">
                <span class="price">Цена: {{ product.price}}</span>

                <router-link :to="`/product/${product.id}`"  custom="true" v-slot="{ navigate, href, route }">
                    <a :href="route.fullPath" > {{product.brand + ': ' +product.name + " " + product.additionalInfo+ " "  + product.deliveryType}}</a>
                </router-link>

            </div>
            <slot></slot>
        </div>

</template>

<script>
    export default {
        data(){
          return {

          }
        },
        props: {
            product: {
                type: Object,
                required: true
            }
        },
        methods:{


        }
    }
</script>

<style scoped lang="scss">
    .product-image{
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #060027;
        @media (max-width: 575.98px) {
            text-align: center;

        }
    }
    .product-image>img{
        height: 240px;
        width: auto;
        position: relative;
        margin: 10px;
        @media (max-width: 1399.98px) {
            height: 230px;

        }

        @media (max-width: 1199.98px) {
            height: 180px;

        }

        @media (max-width: 991.98px) {

            height: 280px;
        }

        @media (max-width: 767.98px) {

            height: 200px;
        }

        @media (max-width: 575.98px) {
            height: 280px;

        }

        @media (max-width: 375.98px) {
            height: 260px;

        }

    }
    .product-item{
        margin-bottom: 30px;
        box-sizing: border-box;
        position: relative;
        width: calc(25% - 30px);
        box-shadow: 10px 10px 20px #efe5fd;
        padding: 20px;
        border-radius: 12px;
        border: 2px solid #cccccc;
        &:hover{
            border: 2px solid #FFC562;
        }

        @media (max-width: 991.98px) {
            width: calc(50% - 30px);

        }
        @media (max-width: 575.98px) {
            width: calc(100% );
            align-items: center;
            display: flex;
            flex-direction: column;
        }
    }
    .product-item-text{
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

    }
    .price{
        padding-top: 5px;
        margin-bottom: 8px;
    }
    a{
        color: black;
        max-width: 250px;
        border: none;
        display: flex;
        flex-wrap: wrap;
        line-height: 1.2;
        list-style-type: none;
        text-decoration: none;
        height: 40px;
        outline: none;
        @media (max-width: 991.98px) {

            margin-bottom: 15px;
        }
    }
    .router-link-active{
        border: none;
        padding: 0;
        margin: 0 ;
        outline: none;
    }
    .router-link-exact-active a {
        border: none;
        outline: none;
    }


</style>