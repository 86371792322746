<template>
    <div class="character-product">
        <div class="container">
          <div class="title-product">
              <h2 class="title">{{productDiscription.title}}</h2>


          </div>


            <tabs>
                <tab name="Описание" :selected="true">
                    <p style="text-indent: 20px">{{productDiscription.discription}}</p>

                    <ul>{{productDiscription.title}}:
                        <li v-for="item in character">{{item}}</li>
                    </ul>
                </tab>
                <tab name="Характеристики">
                    <mytable

                        :dataTable="productDiscription"

                    >

                    </mytable>
                </tab>
                <tab name="Похожие программы">

                    <div class="content-product">

                        <productItemInline
                                v-for="product in SortDuble"
                                :id="product.id"
                                :product="product"
                        >
                            <div class="button-content">
                                <my-button @click="showDialog" type="submit" class="btn-button">Заказать</my-button>
                            </div>
                        </productItemInline>
                    </div>
                </tab>


                <tab name="Доп. лицензии">
                    <div class="content-product">

                        <productItemInline
                                v-for="product in SortDop"
                                :id="product.id"
                                :product="product"
                        >
                            <div class="button-content">
                                <my-button @click="showDialog" type="submit" class="btn-button">Заказать</my-button>
                            </div>
                        </productItemInline>


                    </div>
                </tab>


                <tab name="Сравнение версий">
                    <h1>Why we do it</h1>
                </tab>
                <tab name="Комплектация">
                    <h1>Why we do it</h1>
                </tab>

            </tabs>

        </div>
        </div>
    <Popup v-model:show="dialogVisible">
        <Form
                @emailSend="sendForm"
        >
        </Form>
    </Popup>

</template>

<script>
    import tabs from "@/components/UI/tabs";
    import myButton from "@/components/UI/myButton";
    import mytable from "@/components/UI/table";
    import Popup from "@/components/UI/Popup";
    import Form from "@/components/UI/Form";
    import {mapGetters,mapMutations} from "vuex"
    import tab from "@/components/UI/tab";
    import productItemInline from "@/components/product-item-inline";
    export default {
        components: {
            tabs, tab, myButton,Popup,Form,mytable,productItemInline
        },
        props: {
            character: {
                type: Array,
                required: true
            },
            productDiscription: {
                type: Object,
                required: true
            }

        },

        data() {
                return{
                    dialogVisible: false,
                    callDates: [{
                        name: '',
                        email: '',
                        phone: '',
                        admin_email: '',
                        form_subject: '',
                        project_name: ''
                    }],
                }

            },


        computed: {
            ...mapGetters(['LIST_PRODUCT']),

            SortDop() {

                return this.LIST_PRODUCT.filter(item => {
                    return item.licenceType === "Доп. лицензия"
                })
            },
            SortDuble() {

                return this.LIST_PRODUCT.filter(item => {
                    return item.name === this.productDiscription.name
                })
            },
        },
            methods: {
                showDialog() {
                    this.dialogVisible = true
                },
                sendForm(callData) {
                    this.callDates.push(callData);
                    this.dialogVisible = false;
                },


            },

    }
</script>

<style scoped lang="scss">
.character-product{
    display: flex;
    position: relative;
    padding-bottom: 80px;
}

.content-product{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;

}
.button-content{
    justify-content: center;
}
.btn-button{
    background: #0a53be;
    color: aliceblue;

    font-size: 16px;
    text-transform: uppercase;
    border-radius: 6px;
    text-align: center;
    text-decoration: none;
    padding: 8px 30px 10px 30px;}
</style>