<template>
    <button >
        <slot></slot>
    </button>
</template>

<script>
    export default {

    }
</script>

<style scoped>
button{
    padding: 8px 25px 10px 25px;
    font-size: 16px;
    background: aliceblue;
    text-transform: uppercase;
    border-radius: 6px;
    color: black;
    border: none;


}


</style>