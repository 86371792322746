<template>
    <div class="footer-bottom">

        <div class="container">
            <span> © AMB 2021. Все права защищены</span>

        </div>

    </div>

</template>

<script>
    export default {

    }
</script>

<style scoped lang="scss">
    .footer-bottom{
        padding-top: 12px;
        padding-bottom: 20px;
        display: flex;
        color: white;
        position: relative;
        background: darken(#00173A,2%);
    }

</style>