<template>

        <div class="support-content">
            <div class="container">
            <div class="support-content-title">
                <h2>Техническое сопровождение 1С:ИТС</h2>
                <p class="support-subtite">Информационно-технологическое сопровождение (1С:ИТС) – это комплексная,
                    регулярная и оперативная поддержка, которую компания «Art Manipulated Business» оказывает
                    пользователям программ “1С:Предприятие”в соответствии со Стандартом 1С:ИТС</p>
            </div>
           <div class="support-price">




               <div v-for="its in LIST_ITS " class="support-price-item">
                   <div class="support-price-title">
                       <h5 class="support-name">{{its.name}}
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                               <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/>
                               <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                           </svg>
                       </h5>

                   </div>

                   <div class="support-price-body">
                       <p class="body-text">{{its.smalldiscription}}</p>
                       <p class="body-text">{{its.period}} месяцев</p>
                       <p class="body-text">до {{its.countbase}} баз</p>
                       <p class="body-text"> консультация {{its.counthour}} часов</p>
                       <p class="support-cost">{{its.cost}} тг</p>



                       <ul style="display: none">
                           <li class="li-title">БАЗ НА ПОДДЕРЖКЕ</li>
                           <li class="name-service">БАЗ НА ПОДДЕРЖКЕ</li>
                           <li class="li-title">БАЗ НА ПОДДЕРЖКЕ</li>
                           <li class="name-service">БАЗ НА ПОДДЕРЖКЕ</li>
                           <li class="li-title">БАЗ НА ПОДДЕРЖКЕ</li>
                           <li class="name-service">БАЗ НА ПОДДЕРЖКЕ</li>
                       </ul>
                   </div>
                   <div class="support-price-footer">
                       <my-button @click="showDialog" type="submit" class="button">Заказать</my-button>
                   </div>
               </div>
             </div>
        </div>
            <Popup v-model:show="dialogVisible">
                <Form
                        @emailSend="sendForm"
                >
                </Form>
            </Popup>
    </div>
</template>

<script>
    import {mapGetters,mapMutations} from "vuex"
    import myButton from "@/components/UI/myButton";
    import Popup from "@/components/UI/Popup";
    import Form from "@/components/UI/Form";
    import sectonTitle from "@/components/UI/sectonTitle";
    export default {
        components: {
            myButton, sectonTitle,Popup,Form
        },
        data() {
            return{
                dialogVisible: false,
                callDates: [{
                    name: '',
                    email: '',
                    phone: '',
                    admin_email: '',
                    form_subject: '',
                    project_name: ''
                }],
            }

        },
        computed:{
            ...mapGetters(['LIST_ITS']),


        },
        methods: {
            showDialog() {
                this.dialogVisible = true
            },
            sendForm(callData){
                this.callDates.push(callData);
                this.dialogVisible = false;
            },
        },

    }
</script>

<style scoped lang="scss">
.support-content{
    display: flex;
    position: relative;
    padding-top: 80px;
    background-image: url("../assets/row-bgimage-12.jpg");
    padding-bottom: 80px;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: darken(#06357a, 3%);
        opacity: .90;
        z-index: 1;
    }
}
.support-content-title{
    display: flex;
    color: #cccccc;
    justify-content: space-between;
    position: relative;
    z-index: 10;
    flex-direction: column;
    text-align: center;
    align-items: center;
    @media (max-width: 991px) {
        flex-direction: column;
    }
}
.support-subtite{
    padding-top: 30px;
}
.support-price{
    display: flex;
    padding-top: 60px;
    box-sizing: border-box;
    flex-wrap: wrap;
    z-index: 10;
    position: relative;
}
.support-price-item{
    color: black;
    border-radius: 12px;

    position: relative;
    display: flex;
    background: white;
    z-index: 1000;

    margin-right: auto;
    flex-direction: column;
    box-sizing: border-box;
    width: calc(25% - 10px);
    margin-bottom: 40px;

    @media (max-width: 1199px) {
        width: calc(50% - 30px);
        flex-direction: column;
    }
    @media (max-width: 768px) {
        width: calc(100%);
    }
    @media (max-width: 585px) {
        width: calc(100%);
    }
}
.support-price-title{
    padding: 45px;
    border-bottom: 2px solid #060027;
    border-radius: 12px;
    box-shadow: 0 0 1px #cccccc;
    text-align: center;
    color: #1e2637;
}
.support-name{
    font-size: 34px;
    height: 30px;
    display: flex;
    position: relative;
    align-items: baseline;
    justify-content: center;
    margin-bottom: 30px;

}
.support-name:first-child{
   
}
.support-price-body{
    background: white;
    padding: 25px;
    color: black;
    font-size: 20px;
    text-align: center;

}

.support-cost{
    text-align: center;

    font-size: 24px;
}
ul{
    padding-left: 0;
    align-items: center;
    display: flex;
    flex-direction: column;
    line-height: 1.8;
}
li{
    list-style-type: none;
    margin-bottom: 10px;
}
.li-title{
    font-size: 18px;
    font-weight: bold;
}
.support-price-footer{
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
    position: relative;
    z-index: 500;
}
.button{
    display: flex;
    align-self: center;
    font-size: 16px;
    text-transform: uppercase;
    padding: 8px 45px 10px 45px;
    background: #0a53be;
    border-radius: 8px;
    color: aliceblue;
    z-index: 1000;
}
svg{
    min-height: 26px;
    min-width: 26px;

}
.body-text{
    border-bottom: 1px solid darkgray;
}
</style>