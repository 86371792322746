<template>
    <div class="product"
         :style="inlineStyle"
    >

        <div class="container">

            <div class="product-title">
                <h2>{{product.brand + ': ' +product.name}}</h2>
                <span>Артикул: {{product.articale}} </span>
            </div>
            <div class="product-item">
                <div class="product-image">
                    <img :src="require('../assets/' + product.image)" alt="">
                </div>

                <div class="product-text">
                    <p class="price">Цена: {{product.price}}</p>
                    <p class="small-text">{{product.brand + ': ' +product.name + ' – '+product.smallDiscription}} </p>
                    <my-button @click="showDialog" type="submit" class="button">Заказать</my-button>
                </div>
                 </div>
        </div>


    </div>

    <characterProduct :productDiscription="product" :character="product.details"></characterProduct>

    <itsbonus></itsbonus>

    <serviceProduct></serviceProduct>

    <Popup v-model:show="dialogVisible">
        <Form
                @emailSend="sendForm"
        >
        </Form>
    </Popup>
</template>

<script>
    import {mapGetters} from 'vuex'
    import myButton from "@/components/UI/myButton";
    import Popup from "@/components/UI/Popup";
    import Form from "@/components/UI/Form";
    import productList from "@/components/ProductList";
    import catalog from "@/components/catalog";
    import characterProduct from "@/components/characterProduct";
    import serviceProduct from "@/components/serviceProduct";
    import itsbonus from "@/components/itsbonus";

    export default {
        data() {
            return {dialogVisible: false,
                callDates: [{
                name: '',
                email: '',
                phone: '',
                admin_email: '',
                form_subject: '',
                project_name: ''
            }],
        }

        },
        components: {
            myButton, characterProduct, itsbonus,serviceProduct,Popup,Form,productList,catalog
        },
        methods: {
            showDialog() {
                this.dialogVisible = true
            },
            sendForm(callData){
                this.callDates.push(callData);
                this.dialogVisible = false;
            },


        },
        computed: {
            ...mapGetters(['LIST_PRODUCT']),
            ...mapGetters({ProductProxy: 'product'}),
            id() {
                return this.$route.params.id
            },
            product() {
                return this.ProductProxy(this.id)
            },


            getImage() {
                return require('../assets/' + this.product.background)
            },
            inlineStyle() {
                return {
                    backgroundImage: `url("${this.getImage}")`
                }
            },
            Sort() {
             return this.LIST_PRODUCT.filter(item=>{
                 return item.base === this.product.base
             })

            },
            Post: function () {
                return this.LIST_PRODUCT.slice(0,6).filter(item=>{
                    return item.name !== this.product.name
                })
            }
        },

    }
</script>

<style scoped lang="scss">
 .product{
     margin-bottom: 80px;
     padding-bottom: 80px;
     position: relative;
     display: flex;
     height: 80vh;
     background-attachment: fixed;
     background-position: center;
     background-size: cover;
     object-fit: cover;
     object-position: center;
    @media (max-width: 991px) {
        height: auto;
    }
 }

.product::before {

        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #00173A;
        opacity: .85;
        z-index: 1;
    }

.product-title{
    display: flex;
    justify-content: left;
    padding-top: 180px;
    position: relative;
    flex-direction: column;
    color: #cccccc;
    z-index: 10;
    @media (max-width: 578px) {
        box-sizing: border-box;
        margin: 20px;
    }
}
.product-item{
    display: flex;
    padding-top: 40px;
    position: relative;
    z-index: 10;
    @media (max-width: 768px) {

            flex-direction: column;
            display: flex;
            position: relative;
            align-items: center;
            margin: 15px;
        }
    @media (max-width: 585px) {

        padding-top: 10px;
    }

 }
.product-image{
    z-index: 5;
    display: flex;
    position: relative;

}
img{
    position: relative;
    height: 350px;
    width: auto;
}
 @media (max-width: 1199px) {
    img{
        height: 310px;
    }
 }

 @media (max-width: 991.98px) {
     img{
         height: 250px;
     }
 }
.product-text{
    margin-left: 80px;
    position: relative;
    color: #cccccc;
    max-width: 680px;
    z-index: 10;
    flex-direction: column;
    @media (max-width: 767.98px){
        margin-left: 0;
        padding-top: 30px;
    }

}
.small-text{
    margin-bottom: 60px;
    z-index: 10;
    @media (max-width: 767.98px){

        margin-bottom: 30px;
    }
}
.btn-button{
    padding: 8px 30px 10px 30px;
}
</style>