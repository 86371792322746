<template>



    <ul class="feature">
        <li v-for="item in dataTable.character" >
            <div class="feature-left">{{item.characterType}}</div>
            <div class="feature-right">{{item.characterName}}</div>
        </li>

    </ul>

</template>

<script>
    export default {
        props:{
            dataTable:{
                type: Object,

            }
        }
    }
</script>

<style scoped>

.feature {
    display: block;
    margin: 0;
    padding: 0;
}
.feature li {
    display: block;
    letter-spacing: -1ex;
    margin: 5px 0;
}
.feature-left {
    margin-right: -49%;
    width: 39%;
    vertical-align: top;
    overflow: hidden;
    display: inline-block;
    letter-spacing: normal;
}
.feature-left:after {
    content: '';
    display: inline-block;
    width: 100%;
    margin: 0 -99% 0 3px;
    vertical-align: baseline;
    border-bottom: 1px dashed #888;
}
.feature-right {
    display: inline-block;
    letter-spacing: normal;
    padding-left: 3px;
    margin-left: 49%;
    width: 39%;
    vertical-align: bottom;
}
</style>