<template>
    <div class="top-line">
    <div class="container">
        <div class="top-line-item">
            <div class="top-line-contact"
            v-for="contact in GET_CONTACTS"
            >
                <span class="phone">{{contact.phone}} </span>
                <span class="adress">{{contact.city + ",  ул. " + contact.adress}}  </span>
            </div>

            <div class="social">
                <span> +7 (705) 647 29 99</span>
                <span>Алматы, Макатаева 117 блок Б офис 411</span>
            </div>
        </div>


        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex"
    export default {
        computed:{
            ...mapGetters(['GET_CONTACTS'])
        }
    }
</script>

<style scoped>
.top-line{
    background: #06357a;
    display: none;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
}

@media (max-width: 992px) {
    .top-line{
        display: none;
    }
}
.top-line-item{
    display: flex;
    justify-content: space-between;
}
.top-line-contact{
    display: flex;

}

.phone{
    margin-right: 20px;
}
.social{

}
</style>