import { createRouter, createWebHistory } from 'vue-router'
import Main from "../views/Main";
import Contacts from "../views/Contacts";
import Product from "../views/Product";
import Products from "../views/Products";
import Service from "../views/Service";
import Blogs from "../views/Blogs";
import Snt from "../views/Snt";
import Its from "../views/Its";
import singleService from "../components/single-service";
import Automation from "../views/Automation";
const routes = [
  {
    name: "main",
    path: '/',
    component: Main
  },
  {
    name: "avtomation",
    path: "/automation",
    component: Automation,
  },
  {
    name: "product",
    path: '/product/:id',
    component: Product,

  },
  {
    name: "products",
    path: '/products/',
    component: Products,
    props: true

},
  {
    name: "category",
    path: '/products/:category',
    component: Products,
    props: true
  },


  { name: "service",
    path: '/service/',
    component: Service
  },

  {
    name: "blog",
    path: '/blog/',
    component: Blogs
  },
  {
    name: "contacts",
    path: '/contacts',
    component: Contacts
  },

  {
    name: "its",
    path: '/its/',
    component: Its
  },

  {
    name: "snt",
    path: '/snt/',
    component: Snt
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  scrollBehavior (to, from, savedPosition) {
    if (to.name === 'product' || from.name === '/') {
      return { y: 0 };
    }
    if (to.name === 'products' || from.name === '/') {
      return { y: 0 };
    }
    if (to.name === 'category' || from.name === '/') {
      return { y: 0 };
    }
  },

})

export default router
