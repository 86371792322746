<template>
    <div class="news">
    <div class="container">

            <div class="news-content">
                <h2 class="news-title">Блог</h2>
                <div class="subtitle-content">
                    <p class="new-subtile">Будьте в курсе! Новости и полезные статьи</p>
                    <myButton class="btn-button">Все статьи</myButton>
                </div>


            </div>
           <div class="news-inner">
               <div class="news-items">
                   <div class="news-image">
                       <img src="../assets/automation.jpg" alt="">
                   </div>
                   <div class="content-text">
                       <span class="post-data">27.10.1986</span>
                       <h5 class="post-title">Lorem ipsum dolor sit.</h5>
                       <p class="post-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus, reiciendis.</p>
                   </div>


               </div>

               <div class="news-items">
                   <div class="news-image">
                       <img src="../assets/automation.jpg" alt="">
                   </div>
                   <div class="content-text">
                       <span class="post-data">27.10.1986</span>
                       <h5 class="post-title">Lorem ipsum dolor sit.</h5>
                       <p class="post-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus, reiciendis.</p>
                   </div>


               </div>


               <div class="news-items">
                   <div class="news-image">
                       <img src="../assets/automation.jpg" alt="">
                   </div>
                   <div class="content-text">
                       <span class="post-data">27.10.1986</span>
                       <h5 class="post-title">Lorem ipsum dolor sit.</h5>
                       <p class="post-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus, reiciendis.</p>
                   </div>


               </div>


           </div>
        </div>
    </div>
</template>

<script>
    import myButton from "@/components/UI/myButton";

    export default {
        components: {
            myButton,
        },

    }
</script>

<style scoped lang="scss">
.news{
    display: flex;
    padding-bottom: 80px;
}
.news-content{
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 60px;
}
.subtitle-content{
    margin-left: 200px;
    @media (max-width: 992px) {
        margin-left: 0;
    }
}
.new-subtile{
    font-size: 42px;
    margin-bottom: 10px;
}
.news-inner{
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-wrap: wrap;
}
.news-items{
    display: flex;
    position: relative;
    width: calc(33% - 15px);
    flex-direction: column;
    padding-top: 20px;
    @media (max-width: 1200px) {
        width: calc(50% - 15px);
    }
    @media (max-width: 786px) {
        width: calc(100%);
    }
}
.news-image{
    display: flex;
    position: relative;
}
    img{
        height: 260px;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }
.content-text{
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 10px  20px 10px 0
}
    .post-data{
        color: darken(#cccccc, 55%);
    }
    .post-title{
        padding-top: 10px;
        font-weight: bold;
    }
    .post-text{
        padding-top: 10px;
        color: darken(#cccccc, 55%);
    }
.btn-button{
    background: #0a53be;
    color: aliceblue;
    margin-right: 70px;
    font-size: 16px;
    padding: 8px 30px 10px 30px;}
</style>