<template>
    <div class="vacancy">
        <div class="container">
        <div class="vacancy-content">
            <div class="cariera">
               <p>Карьера в AMB</p>
            </div>
            <div class="vacancy-text">
                <h2>Актуальные вакансии</h2>
                <div class="button-content">
                    <my-button>Все вакансии</my-button>
                </div>

            </div>

        </div>
        </div>
    </div>
</template>

<script>
    import myButton from "@/components/UI/myButton";
    export default {
        components:{

            myButton,
        }
    }
</script>

<style scoped lang="scss">
.vacancy{
    display: flex;
    position: relative;
    padding-top: 80px;
    background-image: url("../assets/row-bgimage-12.jpg");
    padding-bottom: 60px;
    background-position: right;
    background-size: cover;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: darken(#06357a, 3%);
        opacity: .95;
        z-index: 2;
    }
}
    .vacancy-content{
        display: flex;
        justify-content: flex-start;
        align-items: inherit;
        z-index: 5;
        @media (max-width: 576px){
            flex-direction: column;
        }
    }
    .cariera{
        color: white;
        font-size: 24px;
        line-height: 17px;
    }
    .vacancy-text{
        display: flex;
        flex-direction: column;
        margin-left: 100px;
        color: white;
        z-index: 5;
    }
    .button-content{
        display: flex;
        padding-top: 15px;
    }
</style>