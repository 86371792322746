<template>
    <div class="about-snt">


    <div class="container">
        <div class="snt-title">
            <h2>ЧТО ТАКОЕ ВИРТУАЛЬНЫЙ СКЛАД</h2>
        </div>
        <p>
            ЧТО ТАКОЕ ВИРТУАЛЬНЫЙ СКЛАД </p>
        <p> С 1 апреля 2018 года введен в эксплуатацию модуль ИС ЭСФ «Виртуальный склад». </p>

        <p>  <strong>Виртуальный склад </strong> - новый модуль системы ИС, предназначенный для контроля перемещения товаров в автоматическом режиме
            (Модуль ВС). ВС ИС ЭСФ размещен на портале <a href="https://esf.gov.kz:8443/esf-web/">esf-vs.gov.kz.</a>
            </p>
        <p> С помощью нового модуля, государственные органы смогут в онлайн режиме отслеживать движение интересующих товаров с момент их ввоза в РК или производства на территории страны и до момента их списания, реализации и иного выбытия.
             </p>
        <p>  <strong>Товары ВС </strong>Товары ВС – это товары (ТМЗ, ОС и прочие активы), код ТН ВЭД которых входит в список объектов, подлежащих учету в модуле «Виртуальный склад». Перечень товаров ВС определяется КГД МФ РК.
            </p>
        <p>   В ВС отражаются любые движения по товарам виртуального склада.
            </p>
        <p>  <strong>Важно! </strong> Количество складов, отражаемых в модуле ВС, должно соответствовать количеству фактически имеющихся у налогоплательщика складов, торговых точек. Данное требование предусмотрено необходимостью корректности списания товаров, числящихся на складах в учетных системах налогоплательщиков, и складах, создаваемых в модуле ВС.
            </p>

    </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style scoped>
.about-snt{
    display: flex;
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
}
.snt-title{
    padding-bottom: 15px;
    font-size: 44px;
}
    p{
        flex-wrap: wrap;
    }
</style>