<template>
    <div class="footer">
    <div class="container">

            <div class="footer-content">
                <div class="logo logo-footer">
                    <logo></logo>
                    <p class="text">Мы являемся одной из ведущих компаний в сфере внедрения и
                        сопровождения программных продуктов фирмы 1С, оказываем своевременную и
                        оперативнную помощь консультируя по введению учета!</p>
                </div>

                <div class="footer-menu">
                 <ul class="footer-nav">
                    <router-link
                            v-for="item in GET_MENU"
                            :key="item.route"
                            :to="{name: item.route}" replace
                            v-slot="{route, isExactActive, navigate}"
                            :custom="true"
                    >
                        <li class="link" :class="isExactActive ? 'active': ''">
                            <a :href="route.fullPath" @click="navigate">{{item.title}}</a>
                        </li>
                    </router-link>
                </ul>

                </div>
                <div class="footer-contacts"
                     v-for="contact in GET_CONTACTS"
                >
                    <div class="caption">Контакты</div>

                    <div class="caption-content">

                        <div class="caption-item">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                            </svg>
                        </div>
                        <div class="adress">
                            <span>{{contact.country+', '+ contact.city + ",  ул. " + contact.adress}}</span>
                        </div>
                        </div>


                            <div class="caption-item">
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                                </svg>
                            </div>
                            <div class="caption-phone">
                            <a class="phone" href="tel:+77056472999">{{contact.phone}}</a>
                            <a class="phone" href="tel:+77273176620">{{contact.phone1}}</a>
                            </div>
                        </div>

                        <div class="caption-item">
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
                                </svg>
                            </div>
                            <div class="adress">
                                <div class="caption-adress">{{contact.email}}</div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="social-media">
                    <div class="caption"> Мы в социальных сетях</div>
                    <div class="social-content">
                    <div class="social-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                    </svg>
                     </div>
                    <div class="social-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telegram" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
                        </svg>
                    </div>
                    <div class="social-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
                            <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                        </svg>
                    </div>

                    </div>
                </div>
        </div>
        </div>
    </div>
</template>

<script>
    import logo from "@/components/logo";
    import {mapGetters} from "vuex"
    export default {
    components:{
        logo
    },
        computed:{
            ...mapGetters(['GET_MENU']),
            ...mapGetters(['GET_CONTACTS'])
        }
    }
</script>

<style scoped lang="scss">
    .footer{
        display: flex;
        padding-top: 60px;
        padding-bottom: 60px;
        position: relative;
        background: darken(#00173A,2%);
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        box-sizing: border-box;
    }
    .footer-content{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @media (max-width: 760px){
           flex-direction: column;
            margin-left: 15px;
            margin-right: 20px;
            box-sizing: border-box;
        }
    }
    .logo-footer{
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        width: calc(25% - 10px);
        font-weight: normal;
        line-height: 1.6;
        @media (max-width: 1200px){
            width: calc(33% - 10px);
        }
        @media (max-width: 992px){
            width: calc(50% - 10px);
        }
        @media (max-width: 768px){
            width: calc(100% - 10px);
        }

    }
    .text{
        font-size: 16px;
        color: white;
        @media (max-width: 992px){
            font-size: 16px;
        }
        @media (max-width: 768px){
           width: auto;

        }
    }
    .logo{
        align-self: auto;
        display: flex;
    }
    .footer-menu{
        @media (max-width: 768px){
            width: calc(100%);
        }
    }
    .footer-menu,.footer-contacts, .social-media{
        padding-top: 15px;

    }
    .footer-nav{
        padding-left: 0;
    }
    .link{
        color: white;
        margin-bottom: 20px;
        list-style-type: none;
    }
    .link>a{
        color: white;
        font-size: 16px;
        line-height: 1.5em;
        font-weight: 500;
        letter-spacing: .7px;
        text-decoration: none;

    }
    .footer-contacts{
        display: flex;

        flex-direction: column;
        color: white;
    }
    .caption{
        font-size: 16px;
        line-height: 1.4em;
        font-weight: 500;
        color: #fff;
        padding-top: 2px;
        letter-spacing: .7px;
        text-transform: uppercase;
    }
    .caption-item{
        margin: 20px 0;
        color: grey;
        line-height: 1.5em;
        font-weight: 500;
        letter-spacing: .7px;
        display: flex;
        padding-top: 2px;
    }

    .caption-phone{
        display: flex;

        flex-direction: column;
    }
    .caption-phone>a{
        padding-top: 8px;
        color: grey;
        text-decoration: none;
    }
    .social-media{
        color: white;
    }
    .social-content{
        display: flex;
        position: relative;
        padding-top: 15px;
        font-size: 24px;
    }
    .social-icon{
        margin-right: 25px;

    }
    .icon{
        display: flex;
        align-items: center;
        margin-right: 10px;
    }
    svg{
        min-width: 20px;
        min-height: 20px;
    }
</style>