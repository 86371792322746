import { createStore } from 'vuex'

export default createStore({
  state: {
    productTitle: [
      {title: "Предлагаем решение именно для вашего бизнеса",
      "discription": 'Программные продукты 1С\n' +
          'для развития Вашего бизнеса\n' +
          'Готовые решения для любой отрасли'},

    ],

    characterSoft:[
      {
        "id": "1",
        "characterType": "Вид поставки",

      "characterName":
            [ { "id": "1","name": "Soft",},
              { "id": "2","name": "USb",}
            ],
      },

      {
        "id": "2",
        "characterType": "Вид учета",
        "characterName":
            [ { "id": "1","name": "Бухгалтерский учет",},
              { "id": "2","name": "Торговля",}
            ],
      },
      {
        "id": "3",
        "characterType": "Категория",
        "characterName":
            [ { "id": "1","name": "готовое решение",},
              { "id": "2","name": "main",}
            ],
      },
      {
        "id": "4",
        "characterType": "Количество пользователей",
        "characterName":
            [ { "id": "1","name": 1,},

            ],
      },
      {
        "id": "5",
        "characterType": "Обновления",
        "characterName":
            [ { "id": "1","name": "(программная защита)",},
              { "id": "2","name": "Вид USb",}
            ],
      },
      {
        "id": "6",
        "characterType": "Отрасль",
        "characterName":
            [ { "id": "1","name": "Вид учета",},
              { "id": "2","name": "Вид USb",}
            ],
      },
      {
        "id": "7",
        "characterType": "Срок действия лицензии",
        "characterName":
            [ { "id": "1","name": "Вид учета",},
              { "id": "2","name": "Вид USb",}
            ],
      },

    ],


    menu:[
      {"route": "main", "title": "Главная"},
      {"route": "avtomation", "title": "Автоматизация"},
      {"route": "products", "title": "Товары"},
      {"route": "service", "title": "Услуги"},
      {"route": "blog", "title": "Блог"},
      {"route": "contacts", "title": "Контакты"},
    ],

    service:[
      {"id": "1", "title": "Первоначальное обучение", "price": "10 000",
        "discription": "Первоначальное обучение (ознакомление заказчика с порядком ведения учета в конфигурации)" +
            "\n" },
      {"id": "1", "title": "Первоначальное обучение", "price": "10 000",
        "discription": "Первоначальное обучение (ознакомление заказчика с порядком ведения учета в конфигурации)" +
            "\n" },
      {"id": "1", "title": "Выездные консультации", "price": "10 000",
        "discription": "Выездные консультации (обучение) по конкретным вопросам ведения учета в конфигурациях 1С" +
            "\n" },

      {"id": "1", "title": "Исправление ошибок в конфигурации", "price": "10 000",
        "discription": "Поиск, анализ и исправление ошибок в ведении учета конфигурациях 1С "      },
    ],
    contacts: [
      {"id": 1,"country": "Казахстан", "city": "Алматы", "phone": "+7 705 647 29 99", "phone1": "+7 727 317 66 20","adress": " Сатпаева 35А, кв. 14","email": "info@amb-it.kz"}
    ],
    its:[
      {
        "id": 1,
        "name": "ИТС ПРОФ ",
        "cost": "110 000",
        "period": "12",
        "countbase": "3",
        "counthour": "5",
        "smalldiscription": "Небольшая компания без IT специалиста",
        "service":
            [{
              'serviceName': "Обновление 1С",
              "cost": 10000
            }],
      },

      {
        "id": 2,
        "name": "ИТС ПРОФ PLUS",
        "cost": "190 000",
        "period": "12",
        "countbase": "5",
        "counthour": "10",
        "smalldiscription": "Небольшая компания без IT специалиста",
        "service":
            [{
              'serviceName': "Обновление 1С",
              "cost": 10000
            }],
      },

      {
        "id": 3,
        "name": "ИТС ПРОФ Универсал",
        "cost": "290 000",
        "period": "12",
        "countbase": "10",
        "counthour": "20",
        "smalldiscription": "Небольшая компания без IT специалиста",
        "service":
            [{
              'serviceName': "Обновление 1С",
              "cost": 10000
            }],
      },

      {
        "id": 4,
        "name": "ИТС ПРОФ Максимум",
        "cost": "385 000",
        "period": "12",
        "countbase": "20",
        "counthour": "30",
        "smalldiscription": "Небольшая компания без IT специалиста",
        "service":
            [{
              'serviceName': "Обновление 1С",
              "cost": 10000
            }],
      },


    ],
    itsService:[
      {"id":"1", "name":"1С:Обновление программ","discription": "Снижение налоговых рисков предприятия за счет" +
            " своевременного обновления механизмов, формул, шаблонов для ведения учета и формирования отчетности" +
            " в соответствии с последними изменениями законодательства.", "image":"обновление.jpg"
      },
      {"id":"2", "name":"Информационная система 1С:ИТС","discription": "Самый полный информационный ресурс для людей," +
            " которые работают с программами 1С - справочники, методики, руководства, " +
            "консультации по программам и законодательству.", "image":"итс.jpg"
      },
      {"id":"3", "name":"Линия консультаций","discription": "Консультации специалистов Службы технической поддержки " +
            "по 1С:Предприятию и по телефону и электронной почте.", "image":"лк.jpg"
      },
      {"id":"4", "name":"1С:Контрагент","discription": "Быстрая проверка информации о контрагентах, " +
            "автоматическое заполнение реквизитов контрагентов в различных документах и другие полезные функции.",
        "image":"контрагент.jpg"
      },
      {"id":"5", "name":"1С:ДиректБанк","discription": "Прямой обмен электронными документами с банком. " +
            "Позволяет отправлять платежи в банк и получать выписки по расчетным счетам непосредственно из программ 1С," +
            " без переключения в систему Клиент-банк.",
        "image":"директбанк.jpg"
      },
      {"id":"6", "name":"1С:Линк","discription": "Простой способ организовать безопасный удаленный доступ" +
            " через Интернет к программам (информационным базам) 1С:Предприятия, установленным на компьютере пользователя.",
        "image":"линк.jpg"
      },
    ],

    products:[
      { "id": "1",
        "articale": "4601546095817",
        "brand":"1С",
        "category": "Программное обеспечение",
        "licenceType": "Типовое решение",
        "countUser": 1,
        "typeOfAccounting": "Бухгалтерский",
        "kindOfSolution": 'Типовое',
        "city": "Караганда",
        "deliveryType": "USB",
        "additionalInfo": "",
        "name": "Бухгалтерия 8 для Казахстана.",
        "price": 64800,
        "base": false,
        "image": "1.png",
        "background": "snt-bg.jpg",
        "title": "Возможности программы «1C:Бухгалтерия 8 для Казахстана»",
        "smallDiscription": "позволяет вести бухгалтерский " +
            "и налоговый учет хозяйственной деятельности " +
            "нескольких организаций в соответствии с действующим законодательством",
        "discription":
            "Программный продукт «1C:Бухгалтерия 8 для Казахстана» предназначен для автоматизации бухгалтерского и налогового учета," +
            " включая подготовку обязательной (регламентированной) отчетности, в организациях, осуществляющих любые виды коммерческой деятельности: " +
            "торговлю товарами, оказание услуг, производство и т.д. Бухгалтерский и налоговый учет ведется в соответствии с действующим законодательством" +
            " Республики Казахстан.\n" +
            "\n" +
            "Программа подходит для автоматизации учета как небольших организаций, так и организаций со сложной структурой, организациям, " +
            "где учетом занимаются несколько специалистов, а также организациям, которым требуется вести учет по нескольким организациям в одной программе," +
            " или по территориально распределенным базам.",

    details:
        [ 'ведение учета деятельности нескольких организаций',
          'поддержка учета в структурных подразделениях, не выделенных на отдельный баланс',
          'учет товарно-материальных запасов',
        ],

        "character":
            [
              {
                "id": "1",
                "characterType": "Вид поставки",
                "characterName": "Электронная лицензия",
              },

              {
                "id": "2",
                "characterType": "Вид учета",
                "characterName": "Бухгалтерский учет",
              },
              {
                "id": "3",
                "characterType": "Категория",
                "characterName": "готовое решение",
              },
              {
                "id": "4",
                "characterType": "Количество пользователей",
                "characterName": "1",
              },
              {
                "id": "5",
                "characterType": "Обновления",
                "characterName": "да",
              },
              {
                "id": "6",
                "characterType": "Отрасль",
                "characterName": "оптовая торговля / розница / транспорт / сфера услуг / медицина / СТО и автобизнес / производство",
              },
              {
                "id": "7",
                "characterType": "Срок действия лицензии",
                "characterName": "бессрочно",
              },

            ],
        "service": [
          {
            "id": "1",
            "title": "Первоначальное обучение",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Первоначальное обучение (ознакомление заказчика с порядком ведения учета в конфигурации)",
          },

          {
            "id": "2",
            "title": "Выездные консультации",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Выездные консультации (обучение) по конкретным вопросам(ознакомление заказчика с порядком ведения учета в конфигурации)",
          },

          {
            "id": "3",
            "title": "Исправление ошибок в конфигурации",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Поиск, анализ и исправление ошибок в ведении учета",
          },

          {
            "id": "4",
            "title": "Доработка функционала в 1С",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Доработка функционала (доработка и создание дополнительных документов, отчетов, обработок)",
          },

          {
            "id": "5",
            "title": "Настройка 1С",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Настройка интерфейсов, настройка прав, ввод новых пользователей",
          },

          {
            "id": "6",
            "title": "Обновление релизов",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Обновление релизов (конфигурация, платформа, формы отчетности)",
          },

          {
            "id": "7",
            "title": "Решение технических проблем",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Решение технических проблем, связанных с эксплуатацией программного обеспечения",
          },

          {
            "id": "8",
            "title": "Подключение и настройка торгового оборудования",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Подключение и настройка торгового оборудования (сканеры штрихкодов, терминалы сбора данных, принтер этикеток, принтер чеков, весов и т.д.)",
          },

        ],
     },

      {
        "id": "7",
        "articale": "4601546095824",
        "brand":"1С",
        "category": "Программное обеспечение",
        "licenceType": "Типовое",
        "countUser": 5,
        "typeOfAccounting": "Бухгалтерский",
        "kindOfSolution": 'Типовое',
        "city": "Астана",
        "deliveryType": "(программная защита)",
        "additionalInfo": "Комплект на 5 пользователей.",
        "name": "Бухгалтерия 8 для Казахстана.",
        "price": 151200,
        "base": false,
        "image": "1.png",
        "background": "snt-bg.jpg",
        "title": "Возможности программы «1C:Бухгалтерия 8 для Казахстана»",
        "smallDiscription": "позволяет вести бухгалтерский " +
            "и налоговый учет хозяйственной деятельности " +
            "нескольких организаций в соответствии с действующим законодательством",
        "discription":"Программный продукт «1C:Бухгалтерия 8 для Казахстана» предназначен для автоматизации бухгалтерского и налогового учета," +
            " включая подготовку обязательной (регламентированной) отчетности, в организациях, осуществляющих любые виды коммерческой деятельности: " +
            "торговлю товарами, оказание услуг, производство и т.д. Бухгалтерский и налоговый учет ведется в соответствии с действующим законодательством" +
            " Республики Казахстан.\n" +
            "\n" +
            "Программа подходит для автоматизации учета как небольших организаций, так и организаций со сложной структурой, организациям, " +
            "где учетом занимаются несколько специалистов, а также организациям, которым требуется вести учет по нескольким организациям в одной программе," +
            " или по территориально распределенным базам.",

        details:
            [ 'ведение учета деятельности нескольких организаций',
              'поддержка учета в структурных подразделениях, не выделенных на отдельный баланс',
              'учет товарно-материальных запасов',
            ],
        "service": [
          {
            "id": "1",
            "title": "Первоначальное обучение",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Первоначальное обучение (ознакомление заказчика с порядком ведения учета в конфигурации)",
          },

          {
            "id": "2",
            "title": "Выездные консультации",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Выездные консультации (обучение) по конкретным вопросам(ознакомление заказчика с порядком ведения учета в конфигурации)",
          },

          {
            "id": "3",
            "title": "Исправление ошибок в конфигурации",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Поиск, анализ и исправление ошибок в ведении учета",
          },

          {
            "id": "4",
            "title": "Доработка функционала в 1С",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Доработка функционала (доработка и создание дополнительных документов, отчетов, обработок)",
          },

          {
            "id": "5",
            "title": "Настройка 1С",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Настройка интерфейсов, настройка прав, ввод новых пользователей",
          },

          {
            "id": "6",
            "title": "Обновление релизов",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Обновление релизов (конфигурация, платформа, формы отчетности)",
          },

          {
            "id": "7",
            "title": "Решение технических проблем",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Решение технических проблем, связанных с эксплуатацией программного обеспечения",
          },

          {
            "id": "8",
            "title": "Подключение и настройка торгового оборудования",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Подключение и настройка торгового оборудования (сканеры штрихкодов, терминалы сбора данных, принтер этикеток, принтер чеков, весов и т.д.)",
          },

        ],

        "character":
            [
              {
                "id": "1",
                "characterType": "Вид поставки",
                "characterName": "Электронная лицензия",
              },

              {
                "id": "2",
                "characterType": "Вид учета",
                "characterName": "Бухгалтерский учет",
              },
              {
                "id": "3",
                "characterType": "Категория",
                "characterName": "готовое решение",
              },
              {
                "id": "4",
                "characterType": "Количество пользователей",
                "characterName": "5",
              },
              {
                "id": "5",
                "characterType": "Обновления",
                "characterName": "да",
              },
              {
                "id": "6",
                "characterType": "Отрасль",
                "characterName": "оптовая торговля / розница / транспорт / сфера услуг / медицина / СТО и автобизнес / производство",
              },
              {
                "id": "7",
                "characterType": "Срок действия лицензии",
                "characterName": "бессрочно",
              },

            ],

      },

      {
        "id": "5",
        "articale": "4601546095824",
        "brand":"1С",
        "city": "Астана",
        "category": "СОФТ",
        "licenceType": "Типовое решение",
        "countUser": 1,
        "typeOfAccounting": "Управленческий",
        "kindOfSolution": 'Типовое',
        "deliveryType": "(программная защита)",
        "additionalInfo": "",
        "name": "Управление торговлей для Казахстана.",
        "price": 90720,
        "base": false,
        "image": '1.png',
        "background": "display-first.jpg",
        "smallDiscription": "программа позволяет в комплексе автоматизировать задачи оперативного" +
            " и управленческого учета, анализа и планирования торговых операций, обеспечивая тем самым " +
            "эффективное управление современным торговым предприятием." ,

        details:
            [ 'ведение учета деятельности нескольких организаций',
              'поддержка учета в структурных подразделениях, не выделенных на отдельный баланс',
              'учет товарно-материальных запасов',
            ],
        "service": [
          {
            "id": "1",
            "title": "Первоначальное обучение",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Первоначальное обучение (ознакомление заказчика с порядком ведения учета в конфигурации)",
          },

          {
            "id": "2",
            "title": "Выездные консультации",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Выездные консультации (обучение) по конкретным вопросам(ознакомление заказчика с порядком ведения учета в конфигурации)",
          },

          {
            "id": "3",
            "title": "Исправление ошибок в конфигурации",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Поиск, анализ и исправление ошибок в ведении учета",
          },

          {
            "id": "4",
            "title": "Доработка функционала в 1С",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Доработка функционала (доработка и создание дополнительных документов, отчетов, обработок)",
          },

          {
            "id": "5",
            "title": "Настройка 1С",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Настройка интерфейсов, настройка прав, ввод новых пользователей",
          },

          {
            "id": "6",
            "title": "Обновление релизов",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Обновление релизов (конфигурация, платформа, формы отчетности)",
          },

          {
            "id": "7",
            "title": "Решение технических проблем",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Решение технических проблем, связанных с эксплуатацией программного обеспечения",
          },

          {
            "id": "8",
            "title": "Подключение и настройка торгового оборудования",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Подключение и настройка торгового оборудования (сканеры штрихкодов, терминалы сбора данных, принтер этикеток, принтер чеков, весов и т.д.)",
          },

        ],
        "character":
            [
              {
                "id": "1",
                "characterType": "Вид поставки",
                "characterName": "Электронная лицензия",
              },

              {
                "id": "2",
                "characterType": "Вид учета",
                "characterName": "Управленческий учет",
              },
              {
                "id": "3",
                "characterType": "Категория",
                "characterName": "готовое решение",
              },
              {
                "id": "4",
                "characterType": "Количество пользователей",
                "characterName": "1",
              },
              {
                "id": "5",
                "characterType": "Обновления",
                "characterName": "да",
              },
              {
                "id": "6",
                "characterType": "Отрасль",
                "characterName": "оптовая торговля / розница / сфера услуг",
              },
              {
                "id": "7",
                "characterType": "Срок действия лицензии",
                "characterName": "бессрочно",
              },

            ],
      },

      {
        "id": "2",
        "articale": "4601546127969",
        "brand":"1С",
        "category": "Программное обеспечение",
        "city": "Алматы",
        "licenceType": "Типовое",
        "countUser": 1,
        "typeOfAccounting": "Управленческий",
        "kindOfSolution": 'Типовое',
        "deliveryType": "(программная защита)",
        "additionalInfo": "Редакция 2",
        "name": "Комплексная автоматизация 8 для Казахстана.",
        "price": 420000,
        "base": false,
        "image": '1.png',
        "background": "display-first.jpg",
        "smallDiscription": "программа позволяет в комплексе автоматизировать задачи оперативного" +
            " и управленческого учета, анализа и планирования торговых операций, обеспечивая тем самым " +
            "эффективное управление современным торговым предприятием." ,

        details:
            [ 'ведение учета деятельности нескольких организаций',
              'поддержка учета в структурных подразделениях, не выделенных на отдельный баланс',
              'учет товарно-материальных запасов',
            ],
        "service": [
          {
            "id": "1",
            "title": "Первоначальное обучение",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Первоначальное обучение (ознакомление заказчика с порядком ведения учета в конфигурации)",
          },

          {
            "id": "2",
            "title": "Выездные консультации",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Выездные консультации (обучение) по конкретным вопросам(ознакомление заказчика с порядком ведения учета в конфигурации)",
          },

          {
            "id": "3",
            "title": "Исправление ошибок в конфигурации",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Поиск, анализ и исправление ошибок в ведении учета",
          },

          {
            "id": "4",
            "title": "Доработка функционала в 1С",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Доработка функционала (доработка и создание дополнительных документов, отчетов, обработок)",
          },

          {
            "id": "5",
            "title": "Настройка 1С",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Настройка интерфейсов, настройка прав, ввод новых пользователей",
          },

          {
            "id": "6",
            "title": "Обновление релизов",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Обновление релизов (конфигурация, платформа, формы отчетности)",
          },

          {
            "id": "7",
            "title": "Решение технических проблем",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Решение технических проблем, связанных с эксплуатацией программного обеспечения",
          },

          {
            "id": "8",
            "title": "Подключение и настройка торгового оборудования",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Подключение и настройка торгового оборудования (сканеры штрихкодов, терминалы сбора данных, принтер этикеток, принтер чеков, весов и т.д.)",
          },

        ],
        "character":
            [
              {
                "id": "1",
                "characterType": "Вид поставки",
                "characterName": "Электронная лицензия",
              },

              {
                "id": "2",
                "characterType": "Вид учета",
                "characterName": "Бухгалтерский учет",
              },
              {
                "id": "3",
                "characterType": "Категория",
                "characterName": "готовое решение",
              },
              {
                "id": "4",
                "characterType": "Количество пользователей",
                "characterName": "1",
              },
              {
                "id": "5",
                "characterType": "Обновления",
                "characterName": "да",
              },
              {
                "id": "6",
                "characterType": "Отрасль",
                "characterName": "оптовая торговля / розница / транспорт / сфера услуг / медицина / СТО и автобизнес / производство",
              },
              {
                "id": "7",
                "characterType": "Срок действия лицензии",
                "characterName": "бессрочно",
              },

            ],
      },

      {
        "id": "3",
        "articale": "4601546095824",
        "brand":"1С",
        "city": "Астана",
        "category": "Программное обеспечение",
        "licenceType": "Типовое",
        "countUser": 1,
        "typeOfAccounting": "Розничная торговля",
        "kindOfSolution": 'Типовое',
        "deliveryType": "(программная защита)",
        "additionalInfo": "",
        "name": "Розница для Казахстана.",
        "price": 64800,
        "base": false,
        "image": '1.png',
        "background": "display-first.jpg",
        "smallDiscription": "готовое решение для автоматизации всех основных бизнес-процессов" +
            " на предприятиях розничной торговли. Система может эффективно использоваться как в отдельных магазинах, " +
            "так и в распределенных розничных сетях, как в небольших торговых точках, так и в магазинах с большим количеством рабочих мест, " +
            "в том числе и в качестве кассовой программы." ,

        details:
            [ 'ведение учета деятельности нескольких организаций',
              'поддержка учета в структурных подразделениях, не выделенных на отдельный баланс',
              'учет товарно-материальных запасов',
            ],
        "service": [
          {
            "id": "1",
            "title": "Первоначальное обучение",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Первоначальное обучение (ознакомление заказчика с порядком ведения учета в конфигурации)",
          },

          {
            "id": "2",
            "title": "Выездные консультации",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Выездные консультации (обучение) по конкретным вопросам(ознакомление заказчика с порядком ведения учета в конфигурации)",
          },

          {
            "id": "3",
            "title": "Исправление ошибок в конфигурации",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Поиск, анализ и исправление ошибок в ведении учета",
          },

          {
            "id": "4",
            "title": "Доработка функционала в 1С",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Доработка функционала (доработка и создание дополнительных документов, отчетов, обработок)",
          },

          {
            "id": "5",
            "title": "Настройка 1С",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Настройка интерфейсов, настройка прав, ввод новых пользователей",
          },

          {
            "id": "6",
            "title": "Обновление релизов",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Обновление релизов (конфигурация, платформа, формы отчетности)",
          },

          {
            "id": "7",
            "title": "Решение технических проблем",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Решение технических проблем, связанных с эксплуатацией программного обеспечения",
          },

          {
            "id": "8",
            "title": "Подключение и настройка торгового оборудования",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Подключение и настройка торгового оборудования (сканеры штрихкодов, терминалы сбора данных, принтер этикеток, принтер чеков, весов и т.д.)",
          },

        ],


        "character":
            [
              {
                "id": "1",
                "characterType": "Вид поставки",
                "characterName": "Электронная лицензия",
              },

              {
                "id": "2",
                "characterType": "Вид учета",
                "characterName": "Бухгалтерский учет",
              },
              {
                "id": "3",
                "characterType": "Категория",
                "characterName": "готовое решение",
              },
              {
                "id": "4",
                "characterType": "Количество пользователей",
                "characterName": "1",
              },
              {
                "id": "5",
                "characterType": "Обновления",
                "characterName": "да",
              },
              {
                "id": "6",
                "characterType": "Отрасль",
                "characterName": "оптовая торговля / розница / транспорт / сфера услуг / медицина / СТО и автобизнес / производство",
              },
              {
                "id": "7",
                "characterType": "Срок действия лицензии",
                "characterName": "бессрочно",
              },

            ],
      },

      {
        "id": "8",
        "articale": "4601546127006",
        "brand":"1С",
        "category": "soft",
        "city": "Астана",
        "licenceType": "Типовое",
        "countUser": 1,
        "typeOfAccounting": "Бухгалтерский",
        "kindOfSolution": 'Типовое',
        "deliveryType": "(программная защита)",
        "additionalInfo": "",
        "name": "Предприятие 8. ERP Управление предприятием 2 для Казахстана",
        "price": 2500000,
        "base": false,
        "image": '1.png',
        "background": "display-first.jpg",
        "smallDiscription": "готовое решение для автоматизации всех основных бизнес-процессов" +
            " на предприятиях розничной торговли. Система может эффективно использоваться как в отдельных магазинах, " +
            "так и в распределенных розничных сетях, как в небольших торговых точках, так и в магазинах с большим количеством рабочих мест, " +
            "в том числе и в качестве кассовой программы." ,
        details:
            [ 'ведение учета деятельности нескольких организаций',
              'поддержка учета в структурных подразделениях, не выделенных на отдельный баланс',
              'учет товарно-материальных запасов',
            ],
        "service": [
          {
            "id": "1",
            "title": "Первоначальное обучение",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Первоначальное обучение (ознакомление заказчика с порядком ведения учета в конфигурации)",
          },

          {
            "id": "2",
            "title": "Выездные консультации",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Выездные консультации (обучение) по конкретным вопросам(ознакомление заказчика с порядком ведения учета в конфигурации)",
          },

          {
            "id": "3",
            "title": "Исправление ошибок в конфигурации",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Поиск, анализ и исправление ошибок в ведении учета",
          },

          {
            "id": "4",
            "title": "Доработка функционала в 1С",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Доработка функционала (доработка и создание дополнительных документов, отчетов, обработок)",
          },

          {
            "id": "5",
            "title": "Настройка 1С",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Настройка интерфейсов, настройка прав, ввод новых пользователей",
          },

          {
            "id": "6",
            "title": "Обновление релизов",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Обновление релизов (конфигурация, платформа, формы отчетности)",
          },

          {
            "id": "7",
            "title": "Решение технических проблем",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Решение технических проблем, связанных с эксплуатацией программного обеспечения",
          },

          {
            "id": "8",
            "title": "Подключение и настройка торгового оборудования",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Подключение и настройка торгового оборудования (сканеры штрихкодов, терминалы сбора данных, принтер этикеток, принтер чеков, весов и т.д.)",
          },

        ],
        "character":
            [
              {
                "id": "1",
                "characterType": "Вид поставки",
                "characterName": "Электронная лицензия",
              },

              {
                "id": "2",
                "characterType": "Вид учета",
                "characterName": "Бухгалтерский учет",
              },
              {
                "id": "3",
                "characterType": "Категория",
                "characterName": "готовое решение",
              },
              {
                "id": "4",
                "characterType": "Количество пользователей",
                "characterName": "1",
              },
              {
                "id": "5",
                "characterType": "Обновления",
                "characterName": "да",
              },
              {
                "id": "6",
                "characterType": "Отрасль",
                "characterName": "оптовая торговля / розница / транспорт / сфера услуг / медицина / СТО и автобизнес / производство",
              },
              {
                "id": "7",
                "characterType": "Срок действия лицензии",
                "characterName": "бессрочно",
              },

            ],
      },

      {
        "id": "4",
        "articale": "4601546095824",
        "brand":"1С",
        "category": "soft",
        "city": "Астана",
        "licenceType": "Типовое",
        "countUser": 1,
        "typeOfAccounting": "Бухгалтерский",
        "kindOfSolution": 'Типовое',
        "deliveryType": "(программная защита)",
        "additionalInfo": "",
        "name": "Управление нашей фирмой для Казахстана.",
        "price": 90720,
        "base": false,
        "image": '1.png',
        "background": "display-first.jpg",
        "smallDiscription": "позволяет вести бухгалтерский " +
            "и налоговый учет хозяйственной деятельности " +
            "нескольких организаций в соответствии с действующим законодательством" ,

        details:
            [ 'ведение учета деятельности нескольких организаций',
              'поддержка учета в структурных подразделениях, не выделенных на отдельный баланс',
              'учет товарно-материальных запасов',
            ],
        "service": [
          {
            "id": "1",
            "title": "Первоначальное обучение",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Первоначальное обучение (ознакомление заказчика с порядком ведения учета в конфигурации)",
          },

          {
            "id": "2",
            "title": "Выездные консультации",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Выездные консультации (обучение) по конкретным вопросам(ознакомление заказчика с порядком ведения учета в конфигурации)",
          },

          {
            "id": "3",
            "title": "Исправление ошибок в конфигурации",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Поиск, анализ и исправление ошибок в ведении учета",
          },

          {
            "id": "4",
            "title": "Доработка функционала в 1С",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Доработка функционала (доработка и создание дополнительных документов, отчетов, обработок)",
          },

          {
            "id": "5",
            "title": "Настройка 1С",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Настройка интерфейсов, настройка прав, ввод новых пользователей",
          },

          {
            "id": "6",
            "title": "Обновление релизов",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Обновление релизов (конфигурация, платформа, формы отчетности)",
          },

          {
            "id": "7",
            "title": "Решение технических проблем",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Решение технических проблем, связанных с эксплуатацией программного обеспечения",
          },

          {
            "id": "8",
            "title": "Подключение и настройка торгового оборудования",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Подключение и настройка торгового оборудования (сканеры штрихкодов, терминалы сбора данных, принтер этикеток, принтер чеков, весов и т.д.)",
          },

        ],

        "character":
            [
              {
                "id": "1",
                "characterType": "Вид поставки",
                "characterName": "Электронная лицензия",
              },

              {
                "id": "2",
                "characterType": "Вид учета",
                "characterName": "Бухгалтерский учет",
              },
              {
                "id": "3",
                "characterType": "Категория",
                "characterName": "готовое решение",
              },
              {
                "id": "4",
                "characterType": "Количество пользователей",
                "characterName": "1",
              },
              {
                "id": "5",
                "characterType": "Обновления",
                "characterName": "да",
              },
              {
                "id": "6",
                "characterType": "Отрасль",
                "characterName": "оптовая торговля / розница / транспорт / сфера услуг / медицина / СТО и автобизнес / производство",
              },
              {
                "id": "7",
                "characterType": "Срок действия лицензии",
                "characterName": "бессрочно",
              },

            ],
      },

      {
        "id": "9",
        "articale": "4601546095824",
        "brand":"1С",
        "category": "soft",
        "city": "Астана",
        "licenceType": "Типовое",
        "countUser": 1,
        "typeOfAccounting": "Бухгалтерский",
        "kindOfSolution": 'Типовое',
        "deliveryType": "(программная защита)",
        "additionalInfo": "",
        "name": "Зарплата и управление персоналом.",
        "price": 90720,
        "base": false,
        "image": '1.png',
        "background": "display-first.jpg",
        "smallDiscription": "позволяет вести бухгалтерский " +
            "и налоговый учет хозяйственной деятельности " +
            "нескольких организаций в соответствии с действующим законодательством" ,

        details:
            [ 'ведение учета деятельности нескольких организаций',
              'поддержка учета в структурных подразделениях, не выделенных на отдельный баланс',
              'учет товарно-материальных запасов',
            ],
        "service": [
          {
            "id": "1",
            "title": "Первоначальное обучение",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Первоначальное обучение (ознакомление заказчика с порядком ведения учета в конфигурации)",
          },

          {
            "id": "2",
            "title": "Выездные консультации",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Выездные консультации (обучение) по конкретным вопросам(ознакомление заказчика с порядком ведения учета в конфигурации)",
          },

          {
            "id": "3",
            "title": "Исправление ошибок в конфигурации",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Поиск, анализ и исправление ошибок в ведении учета",
          },

          {
            "id": "4",
            "title": "Доработка функционала в 1С",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Доработка функционала (доработка и создание дополнительных документов, отчетов, обработок)",
          },

          {
            "id": "5",
            "title": "Настройка 1С",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Настройка интерфейсов, настройка прав, ввод новых пользователей",
          },

          {
            "id": "6",
            "title": "Обновление релизов",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Обновление релизов (конфигурация, платформа, формы отчетности)",
          },

          {
            "id": "7",
            "title": "Решение технических проблем",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Решение технических проблем, связанных с эксплуатацией программного обеспечения",
          },

          {
            "id": "8",
            "title": "Подключение и настройка торгового оборудования",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Подключение и настройка торгового оборудования (сканеры штрихкодов, терминалы сбора данных, принтер этикеток, принтер чеков, весов и т.д.)",
          },

        ],

        "character":
            [
              {
                "id": "1",
                "characterType": "Вид поставки",
                "characterName": "Электронная лицензия",
              },

              {
                "id": "2",
                "characterType": "Вид учета",
                "characterName": "Бухгалтерский учет",
              },
              {
                "id": "3",
                "characterType": "Категория",
                "characterName": "готовое решение",
              },
              {
                "id": "4",
                "characterType": "Количество пользователей",
                "characterName": "1",
              },
              {
                "id": "5",
                "characterType": "Обновления",
                "characterName": "да",
              },
              {
                "id": "6",
                "characterType": "Отрасль",
                "characterName": "оптовая торговля / розница / транспорт / сфера услуг / медицина / СТО и автобизнес / производство",
              },
              {
                "id": "7",
                "characterType": "Срок действия лицензии",
                "characterName": "бессрочно",
              },

            ],
      },

      {
        "id": "11",
        "artical": "4601546080875",
        "brand":"1С",
        "category": "soft",
        "city": "Астана",
        "licenceType": "Доп. лицензия",
        "countUser": 1,
        "typeOfAccounting": "",
        "kindOfSolution": 'Типовое',
        "deliveryType": "(программная защита)",
        "additionalInfo": "",
        "name": "Предприятие 8 ПРОФ. Клиентская лицензия на одно рабочее место",
        "price": 32400,
        "base": false,
        "image": '1.png',
        "background": "display-first.jpg",
        "smallDiscription": "позволяет вести бухгалтерский " +
            "и налоговый учет хозяйственной деятельности " +
            "нескольких организаций в соответствии с действующим законодательством" ,


      },

      {
        "id": "12",
        "artical": "4601546080882",
        "brand":"1С",
        "category": "soft",
        "city": "Астана",
        "licenceType": "Доп. лицензия",
        "countUser": 5,
        "typeOfAccounting": "",
        "kindOfSolution": 'Типовое',
        "deliveryType": "(программная защита)",
        "additionalInfo": "",
        "name": "Предприятие 8 ПРОФ. Клиентская лицензия на 5 рабочих мест",
        "price": 112320,
        "base": false,
        "image": '1c.png',
        "background": "display-first.jpg",
        "smallDiscription": "позволяет вести бухгалтерский " +
            "и налоговый учет хозяйственной деятельности " +
            "нескольких организаций в соответствии с действующим законодательством" ,
      },

      {
        "id": "6",
        "articale": "4601546129871",
        "brand":"1С",
        "category": "soft",
        "city": "Астана",
        "licenceType": "Отраслевое",
        "countUser": 1,
        "typeOfAccounting": "Розничная торговля",
        "kindOfSolution": 'Типовое',
        "deliveryType": "",
        "additionalInfo": "Базовая версия",
        "name": "Предприятие 8. Розница для Казахстана.",
        "price": 21000,
        "base": false,
        "image": '1c.png',
        "background": "display-first.jpg",

        details:
            [ 'ведение учета деятельности нескольких организаций',
              'поддержка учета в структурных подразделениях, не выделенных на отдельный баланс',
              'учет товарно-материальных запасов',
            ],
        "service": [
          {
            "id": "1",
            "title": "Первоначальное обучение",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Первоначальное обучение (ознакомление заказчика с порядком ведения учета в конфигурации)",
          },

          {
            "id": "2",
            "title": "Выездные консультации",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Выездные консультации (обучение) по конкретным вопросам(ознакомление заказчика с порядком ведения учета в конфигурации)",
          },

          {
            "id": "3",
            "title": "Исправление ошибок в конфигурации",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Поиск, анализ и исправление ошибок в ведении учета",
          },

          {
            "id": "4",
            "title": "Доработка функционала в 1С",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Доработка функционала (доработка и создание дополнительных документов, отчетов, обработок)",
          },

          {
            "id": "5",
            "title": "Настройка 1С",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Настройка интерфейсов, настройка прав, ввод новых пользователей",
          },

          {
            "id": "6",
            "title": "Обновление релизов",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Обновление релизов (конфигурация, платформа, формы отчетности)",
          },

          {
            "id": "7",
            "title": "Решение технических проблем",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Решение технических проблем, связанных с эксплуатацией программного обеспечения",
          },

          {
            "id": "8",
            "title": "Подключение и настройка торгового оборудования",
            "price": "10 000",
            "image": '3.svg',
            "discription": "Подключение и настройка торгового оборудования (сканеры штрихкодов, терминалы сбора данных, принтер этикеток, принтер чеков, весов и т.д.)",
          },

        ],

        "character":
            [
              {
                "id": "1",
                "characterType": "Тип лицензии",
                "characterName": "USB",
              },

              {
                "id": "2",
                "characterType": "Размер",
                "characterName": "42",
              },


            ],

      },
    ],




  },
  getters: {
    GET_MENU(state){
      return state.menu
    },
    GET_ITS_SERVICE(state){
      return state.itsService
    },
    GET_characterSoft(state){
      return state.characterSoft
    },
    LIST_PRODUCT(state) {
      return state.products;

    },
    LIST_ITS(state) {
      return state.its;

    },
    LIST_SERVICE(state){
      return state.service;
    },
    productsMap(state) {
      let map = {};
      state.products.forEach((pr, i) => {
        map[pr.id.toString()] = i;
      });
      return map;

    },
    product: (state,getters) => id => state.products[getters.productsMap[id]],
    search(state){
      return state.title;
    },
    GET_TITLE(state){
        return state.productTitle
      },

      GET_CONTACTS(state){
        return state.contacts
      },
    },

  mutations: {

  },
  actions: {
  },
  modules: {
  },
})
