<template>
    <div class="product-section">
    <div class="container">
        <div class="product-title"
            v-for="title in GET_TITLE">
            <div class="title-text">
               <h2 class="title">{{title.title}}</h2>
              <p class="subtitle">{{title.discription}}</p>
            </div>

        </div>

        <div :class="{'product-list': true}">
            <productItem
                    v-for="product in Post"
                    :id="product.id"
                    :product="product"
            >
                <div class="button-content">
                    <my-button @click="showDialog" type="submit" :class="{'button': true}">Заказать</my-button>
                </div>
            </productItem>

        </div>

        <div class="button-content">
            <router-link  :to="{name:'category' , params: {category: category }}"  custom="true" v-slot="{ navigate, href, route }">
                <a :class="{'button': true}" :href="route.fullPath" > Перейти в каталог</a>
            </router-link>

        </div>
        <Popup v-model:show="dialogVisible">
           <Form
            @emailSend="sendForm"
           >
           </Form>
        </Popup>

    </div>

    </div>

</template>

<script>
    import {mapGetters,mapMutations} from "vuex"
    import myButton from "@/components/UI/myButton";
    import Popup from "@/components/UI/Popup";
    import Form from "@/components/UI/Form";
    import catalog from "@/components/catalog";
    import productItem from "@/components/product-item";
    import sectonTitle from "@/components/UI/sectonTitle";


    export default {
        components: {
            myButton, sectonTitle,Popup,Form,catalog,productItem
        },
        data() {
            return{
                category: 'Программное обеспечение',
                dialogVisible: false,
                product: '1C',
                callDates: [{
                    name: '',
                    email: '',
                    phone: '',
                    admin_email: 'gglu@amb-it.kz',
                    form_subject: 'Заявка',
                    product: '1C',
                    project_name: 'AMB'
                }],
            }

        },



        methods: {
            showDialog() {
                this.dialogVisible = true
            },
            sendForm(callData) {
                this.callDates.push(callData);
                this.dialogVisible = false;
            },



        },
        computed:{
            ...mapGetters(['GET_TITLE']),
            ...mapGetters(['LIST_PRODUCT']),
            Post: function () {
                return this.LIST_PRODUCT.slice(0,8)
            },

        },




    }
</script>

<style lang="scss" scoped>
.product-section{
    display: flex;
    position: relative;
    padding-bottom: 80px;
}
.product-title{
    display: flex;
    padding-top: 80px;
    justify-content: center;

}
.title{
    font-size: 42px;
}
.title-text{
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 60%;
    @media (max-width: 575.98px) {
        width: 80%;

    }
}
.subtitle{
    padding-top: 20px;
}
.product-list{
    padding-top: 60px;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;


}

.button-content{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 45px;
}
.button{
    padding: 12px 30px 12px 30px !important;
    text-transform: uppercase;
    border-radius: 8px;
    background: #0a53be;
    font-size: 16px;
    color: white;
    justify-content: center;
}
.button:hover{
    background: #1e2637;
    opacity: 0.9;
    color: white;
}


</style>