<template>

    <div class="nav-content">
        <logo/>
        <div class="navbar">
                <ul class="nav">
                   <router-link
                           v-for="item in GET_MENU"
                           :key="item.route"
                           :to="{name: item.route}"
                           replace
                           v-slot="{route, isExactActive, navigate}"
                           custom="true"
                            >
                       <li class="link" :class="isExactActive ? 'active': ''">
                           <a :href="route.fullPath" >{{item.title}}</a>
                       </li>
                   </router-link>
                </ul>

            <div class="phone"
                 v-for="contact in GET_CONTACTS"
            >
                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                    </svg>
                </div>

                <a href="tel:+77056472999">{{contact.phone}}</a>
            </div>
            <div class="callback">
                <my-button @click="showDialog" type="submit" class="button">Заказать звонок</my-button>
            </div>
            <Gamburger/>
            <Popup v-model:show="dialogVisible">
                <Form
                        @emailSend="sendForm"
                >
                </Form>
            </Popup>
        </div>
    </div>


</template>

<script>
    import logo from "@/components/logo";
    import Gamburger from "@/components/UI/Gamburger";
    import {mapGetters,mapMutations} from "vuex"
    import myButton from "@/components/UI/myButton";
    import Popup from "@/components/UI/Popup";
    import Form from "@/components/UI/Form";
    import sectonTitle from "@/components/UI/sectonTitle";
    export default {
        components:{
            logo,  myButton, sectonTitle,Popup,Form, Gamburger
        },

        data() {
            return{
                dialogVisible: false,
                callDates: [{
                    name: '',
                    email: '',
                    phone: '',
                    admin_email: '',
                    form_subject: '',
                    project_name: ''
                }],
            }

        },

        methods: {
            showDialog() {
                this.dialogVisible = true
            },
            sendForm(callData){
                this.callDates.push(callData);
                this.dialogVisible = false;
            },

        },

        computed:{
            ...mapGetters(['GET_MENU']),
            ...mapGetters(['GET_CONTACTS'])
        }
    }
</script>

<style scoped lang="scss">
.active{



}

.nav-content {
    display: flex;
    padding-top: 5px;
    position: relative;
    justify-content: space-between;

    @media (max-width: 1399.98px) {


    }

    @media (max-width: 1199.98px) {


    }

    @media (max-width: 991.98px) {


    }

    @media (max-width: 767.98px) {


    }

    @media (max-width: 575.98px) {


    }

}
.navbar{
    display: flex;
    align-self: center;
    text-align-all: center;

}
.nav{
    text-decoration: none;
    display: flex;
    color: white;
    border: none;
    @media (max-width: 1199.98px) {

        display: none;

    }
}


.link {
    list-style-type: none;
    text-decoration: none;
    font-size: 16px;
    line-height: 1.8;
    margin-right: 60px;
    @media (max-width: 1399.98px) {
        margin-right: 30px;

    }

    @media (max-width: 1199.98px) {


    }

    @media (max-width: 991.98px) {


    }

    @media (max-width: 767.98px) {


    }

    @media (max-width: 575.98px) {


    }

}



a{
    color: white;
    opacity: 0.9;

    text-decoration: none;
    border: none;
    padding: 0;
    margin: 0;
}
a:hover{
    color: #5B8EDA;
}
.icon{
    color: white;
    font-size: 20px;
    display: flex;
    align-items: center;
    margin-right: 10px;
}
.phone{
    display: flex;
    margin-right: 35px;
    margin-left: 15px;
    line-height: 1.8;
    padding-top: 4px;
    font-size: 14px;
    @media (max-width: 1399.98px) {
        margin-right: 15px;

    }

    @media (max-width: 1199.98px) {
        display: none;

    }

    @media (max-width: 991.98px) {


    }

    @media (max-width: 767.98px) {


    }

    @media (max-width: 575.98px) {


    }

}

.callback{
    display: flex;
    margin-left: 10px;
    font-size: 16px;
    @media (max-width: 1199.98px) {

        display: none;

    }
}

</style>