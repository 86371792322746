<template>
    <div>

                    <form class="form" @submit.prevent="onSubmit" >
                            <input type="hidden" name="project_name" value="AMB">
                            <input type="hidden" name="admin_email" value="gglu@amb-it.kz">
                            <input type="hidden" name="form_subject" value="Заявка">
                            <input type="hidden" name="product" value="product">
                        <div class="form-header">
                         <p class="form-header-title">Оставьте заявку</p>
                         <p>Наши специалисты свяжутся с вами в ближайшее время</p>
                        </div>
                        <div class="form-body">
                            <input type="text" name="name" placeholder="Ваше имя" v-model="callData.name">
                            <input type="text" name="email" placeholder="Ваша почта" v-model="callData.email">
                            <input type="text" name="phone" placeholder="Ваш телефон" v-model="callData.phone" >
                        </div>
                        <div class="form-footer">
                            <myButton class="btn-button" v-model:show="dialogVisible" @click="emailSend" type="submit">Отправить</myButton>
                        </div>
                        <span class="small-content">*Гарантируем 100% сохранность Ваших данных</span>
                    </form>
                </div>


</template>

<script>

    import myButton from "@/components/UI/myButton";
    import axios from 'axios';
    export default {
        components: {
            myButton
        },

        data() {
            return {
                product: '1C',
                callData: {
                    name: '',
                    email: '',
                    phone: '',
                    admin_email: 'gglu@amb-it.kz',
                    form_subject: 'Заявка',
                    product: '1C',
                    project_name: 'AMB'
                },


            }
        },




        methods: {

            emailSend() {
                this.$emit('emailSend', this.callData)
                const params = new URLSearchParams();
                params.append('name', this.callData.name);
                params.append('email', this.callData.email);
                params.append('phone', this.callData.phone);
                params.append('project_name', this.callData.project_name);
                params.append('admin_email', this.callData.admin_email);
                params.append('form_subject', this.callData.form_subject);
                params.append('product', this.callData.product);

                axios.post(
                    "/mail.php",
                    params, {
                        headers: {
                            'content-type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(function (responce) {
                    console.log('SUCCESS!!');


                })
                    .catch(function () {
                        console.log('FAILURE!!');

                    });


            },


        }


    }
</script>

<style scoped lang="scss">

    .form{
        display: flex;
        flex-direction: column;
        background: white;
        border-radius: 12px;
        box-shadow: 0 0 10px #cccccc;
        align-items: center;
        padding: 20px;


    }
    .form-header{
        padding-top: 15px;
        margin: 0 5px;
    }
    .form-header-title{
        font-size: 24px;
    }
    .form-body{
        display: flex;
        flex-direction: column;
        align-items: center;

    }
    input{
        display: flex;
        margin: 15px;
        border-radius: 4px;
        width: 100%;
        height: 35px;
        padding-left: 10px;
        border-bottom: 2px solid black;
    }
    .form-footer{
        display: flex;
        padding-top: 20px;
        justify-content: center;
        align-items: center;
        padding-bottom: 30px;
    }
    .btn-button{
        background: #0a53be;
        color: aliceblue;
        font-size: 16px;
        padding: 8px 30px 10px 30px;
    }
    .small-content{
        font-size: 12px;
    }
</style>