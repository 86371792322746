<template>
    <div class="about-its">
    <div class="container">
        <div class="about-its-title">
        <h2 class="its-title">Что такое ИТС, что в него входит и почему без него нельзя</h2>
        <p class="its-subtitle">ИТС (Информационно-технологическое сопровождение) - это комплекс услуг и полезных сервисов,
            гарантирующий актуальность, работоспособность программы, круглосуточную поддержку,
            удобство работы в 1С, а также доступ к десятку справочников.</p>

           <p class="its-subtitle"> Это официальная поддержка разработчика и Сертифицированного Сервисного партнера «1С».
               Любая поддержка и услуги предоставляются только при наличии договора ИТС.</p>
        </div>

        <div class="about-its-content">

            <div class="about-its-item">
                <div class="its-item-image">
                    <img src="../assets/icon_01.png" alt="">
                </div>
                <div class="its-item-body">
                    <span class="item-title">Обновление программ 1С</span>
                    <p class="its-item-text">Персональный программист обновит вашу программу 1С с гарантией сохранности баз.
                        У вас всегда будут актуальные формы отчетности и обновленный функционал, в связи с изменениями законодательства.</p>
                </div>
            </div>

            <div class="about-its-item">
                <div class="its-item-image">
                    <img src="../assets/icon_02.png" alt="">
                </div>
                <div class="its-item-body">
                    <span class="item-title">Линия консультации 24/7</span>
                    <p class="its-item-text">Специалисты помогут решить ваши вопросы по работе в 1С.
                        Проконсультируем по телефону, электронной почте, через сервис 1С-Коннект.</p>
                </div>
            </div>

            <div class="about-its-item">
                <div class="its-item-image">
                    <img src="../assets/icon_03.png" alt="">
                </div>
                <div class="its-item-body">
                    <span class="item-title">Услуги программистов 1С</span>
                    <p class="its-item-text">Настройка интерфейса, отчетов, прав доступа, обмена между конфигурациями.
                        Закрытие месяца, сдача отчетности, установка, перенос и «чистка» баз,
                        создание архивных копий баз, разъяснение изменений законодательства и др.</p>
                </div>
            </div>

            <div class="about-its-item">
                <div class="its-item-image">
                    <img src="../assets/icon_04.png" alt="">
                </div>
                <div class="its-item-body">
                    <span class="item-title">Сервисы 1С ИТС</span>
                    <p class="its-item-text">Упростите работу в 1С с помощью бесплатных сервисов, входящих в договор ИТС.
                        Бесплатно настроим популярные сервисы: 1С-Облачный архив, 1С:Контрагент, 1С-ЛИНК, 1С-Коннект.</p>
                </div>
            </div>

        </div>
    </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style scoped lang="scss">
.about-its{
    display: flex;
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
}
.about-its-title{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;

}
.its-title{
    margin-bottom: 30px;
}
.its-subtitle{
    display: flex;
    text-indent: 20px;
    position: relative;
    justify-content: center;
}
.about-its-content{
    display: flex;
    padding-top: 60px;
    flex-wrap: wrap;
    justify-content: space-between;
}
.about-its-item{
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 25%;
    @media (max-width: 992px) {
        width: calc(50% - 22px);
    }
    @media (max-width: 768px) {
        width: calc(100% );
    }
}
.its-item-image{
    display: flex;
    text-align: center;
    justify-content: center;
}
 .item-title{
     display: flex;
     justify-content: center;
     font-size: 18px;
     font-weight: bold;
     padding-top: 0px;
     padding-bottom: 15px;
 }
 .its-item-text{
     display: flex;
     margin: 10px;
 }
</style>