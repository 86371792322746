<template>

    <h2><slot></slot></h2>
    <p><slot></slot></p>

</template>

<script>
    export default {

    }
</script>

<style scoped>

</style>