<template>
    <div class="product-item-inline"

    >
        <div class="product-item-inline-image">
            <img :src="require('../assets/' + product.image)" alt="">
        </div>
        <div class="product-item-text">
            <div class="item-text-title">
                <router-link :to="`/product/${product.id}`"  custom="true" v-slot="{ navigate, href, route }">
                    <a :href="route.fullPath" > {{product.brand + ': ' +product.name + " " + product.additionalInfo+ " "  + product.deliveryType}}</a>
                </router-link>
            </div>
            <div class="item-text-text">
                <p class="text">{{product.brand + ': ' +product.name+' ' + product.smallDiscription}}</p>
            </div>
        </div>
        <div class="content-price">

            <div class="product-item-price">
                <p>{{product.price}} тг</p>
            </div>
           <slot></slot>

        </div>

    </div>
</template>

<script>
    export default {
        props: {
            product: {
                type: Object,
                required: true
            }
        },
    }
</script>

<style scoped lang="scss">
    .product-item-inline{
        display: flex;
        align-items: flex-start;
        padding: 30px;
        margin-bottom: 30px;
        min-width: 100%;
        border: 2px solid #cccccc;
        border-radius: 12px;

        max-height: max-content;
        &:hover{
            border: 2px solid #FFC562;
        }

        @media (max-width: 1199px) {
            flex-direction: column;
            max-height: max-content;
            text-align: center;
            justify-content: center;
            align-items: center;

        }

        @media (max-width: 992px) {
            flex-wrap: wrap;
            max-height: max-content;
            padding-left: 0;
            padding-right: 0;
            justify-content: center;
            flex-direction: column;
            align-items: center;

        }
    }
    .product-item-inline-image{
        display: flex;


        margin-right: 30px;
    }
    .product-item-inline-image>img{
        height: 120px;
    }
    .product-item-text{
        max-width: 768px;
        min-width: 300px;
        flex-wrap: wrap;
        display: flex;
        flex-direction: column;
        margin-right: 30px;

        @media (max-width: 992px) {
            padding-top: 30px;
            justify-content: center;
            align-items: center;
            margin: 0 15px;
        }
    }
    .item-text-title {
        @media (max-width: 1200px) {
            text-align: left;
            max-width: 400px;
        }
    }
    .item-text-text,.product-item-price{
        margin-right: 50px;
        @media (max-width: 1200px) {
            margin-right: 0;
        }
    }
    .item-text-text{
        padding-top: 10px;
        @media (max-width: 1200px) {
            max-width: 400px;
        }
    }
    .text{
        text-align: left;
    }
    .content-price{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        @media (max-width: 1200px) {
            margin-left: 0;
        }
    }
    .product-item-price{
        min-width: 100px;
        display: flex;
        margin: 0;
        justify-content: center;
        text-align: center;
    }

    a{
        color: black;
        font-size: 16px;
        font-weight: bold;
        border: none;
        display: flex;
        flex-wrap: wrap;
        line-height: 1.2;
        list-style-type: none;
        text-decoration: none;
        height: 40px;
        outline: none;
        &:hover{
            color: #1e2637;
        }
        @media (max-width: 991.98px) {

            margin-bottom: 15px;
        }
    }
    .router-link-active{
        border: none;
        padding: 0;
        margin: 0 ;
        outline: none;
    }
    .router-link-exact-active a {
        border: none;
        outline: none;

    }
</style>