<template>
    <div class="its-service">
        <div class="container">
            <div class="its-service-title">
                <h2 class="title">Сервисы входящие в договор 1С:ИТС</h2>
                <p class="subtitle">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt, vero.</p>
            </div>
            <div class="its-service-content">

                <div class="its-content-item"
                     v-for="item in GET_ITS_SERVICE"
                     :key="item.id"
                >
                    <div class="content-image">
                        <img  @click=""  :src="require('../assets/' + item.image)"
                              :alt="item.image"
                        >
                    </div>
                    <div class="its-content-text">
                        <h5 class="content-title">{{item.name}}</h5>
                        <p class="content-subtitle">{{item.discription}}</p>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        computed: {
            ...mapGetters(['GET_ITS_SERVICE']),
    },
    }
</script>

<style scoped lang="scss">
    .its-service{
        display: flex;
        position: relative;
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .its-service-title{
        display: flex;
        position: relative;
        justify-content: center;
        text-align: center;
        padding-bottom: 60px;
        flex-direction: column;
    }
    .its-service-content{
        display: flex;
        justify-content: space-between;
        position: relative;
        flex-wrap: wrap;
    }
    .its-content-item{
        display: flex;
        width: calc(33% - 22px);
        flex-direction: column;
        position: relative;
        border: 1px solid grey;
        margin-bottom: 40px;
        box-sizing: border-box;
        box-shadow: 10px 10px 20px #efe5fd;
        border-radius: 12px;
        @media (max-width: 992px) {
            width: calc(50% - 22px);
        }
        @media (max-width: 768px) {
            width: calc(100% );
        }
    }
    .content-image{
        display: flex;
        position: relative;
        margin: 25px;
    }
    .its-content-text{
        display: flex;
        margin: 25px;

        flex-direction: column;
        position: relative;
    }
</style>