<template>
    <div>
        <div class="tabs">
            <ul class="list-tab">
                <li class="tab-item" v-for="tab in tabs" :class="{ 'is-active': tab.isActive }">
                    <a class="item" :href="tab.href" @click="selectTab(tab)">{{ tab.name }}</a>
                </li>
            </ul>
        </div>

        <div class="tabs-details">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import tab from "@/components/UI/tab";
    export default {
        components:{
            tab
        },
        data() {
            return {tabs: [] };
        },

        created() {



        },
        methods: {
            selectTab(selectedTab) {
                this.tabs.forEach(tab => {
                    tab.isActive = (tab.name === selectedTab.name);
                });
            }
        }
    };
</script>

<style scoped lang="scss">
.tabs{
    display: flex;
    position: relative;

}
.list-tab{
    display: flex;
    padding-left: 0;
    padding-top: 40px;
    min-width: 100%;
    position: relative;
    @media (max-width: 992px) {
        flex-wrap: wrap;

    }
}
.tab-item{
    list-style-type: none;
    background: #5c636a;
    color: white;
    padding: 8px 25px 10px 25px;
    align-items: center;
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
}
.item{
    color: white;
    position: relative;
    text-align: center;

}
</style>