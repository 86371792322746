<template>
    <div class="content-single-service">
    <div class="container">

        <div class="single-service">
            <h2 class="single-service-title">Услуги по доработке и настройке 1С</h2>
            <p class="service-text">Разработаем для Вас новый функционал: отчеты, печатные формы и др. Настроим права, клиент-банк,
                интеграцию с другими системами, интеграцию с сайтами и торговым оборудованием</p>
        </div>

        <div class="content-product">
            <div class="content-product-item"
                 v-for="service in product.service"
            >
                <div class="content-product-item-image">
                    <img :src="require('../assets/' + service.image)" alt="">
                </div>
                <div class="product-item-text">
                    <div class="item-text-title">
                        <h5> {{service.title }}</h5>
                    </div>
                    <div class="item-text-text">
                        <p>{{service.discription}}</p>
                    </div>
                </div>
                <div class="product-item-price">
                    <p>{{service.price}} тг</p>
                </div>
                <div class="button-content">
                    <my-button @click="showDialog" type="submit" class="btn-button">Заказать</my-button>
                </div>
            </div>
        </div>
    </div>
    </div>
    <Popup v-model:show="dialogVisible">
        <Form
                @emailSend="sendForm"
        >
        </Form>
    </Popup>
</template>

<script>
    import {mapGetters} from 'vuex'
    import myButton from "@/components/UI/myButton";
    import Popup from "@/components/UI/Popup";
    import Form from "@/components/UI/Form";
    export default {
        components:{
            myButton, Popup, Form
        },
        data() {
            return{
                dialogVisible: false,
                callDates: [{
                    name: '',
                    email: '',
                    phone: '',
                    admin_email: '',
                    form_subject: '',
                    project_name: ''
                }],
            }

        },
        computed: {
            ...mapGetters(['GET_TITLE', 'LIST_PRODUCT']),
            ...mapGetters({ProductProxy: 'product'}),
            id() {
                return this.$route.params.id
            },
            product() {
                return this.ProductProxy(this.id)
            },
        },
        methods: {
            showDialog() {
                this.dialogVisible = true
            },
            sendForm(callData){
                this.callDates.push(callData);
                this.dialogVisible = false;
            },


        },
    }
</script>

<style scoped lang="scss">
    .content-single-service{
        padding-top: 80px;
        display: flex;
        position: relative;
        padding-bottom: 80px;
    }
    .single-service{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;


    }
    .service-text{
        text-align: center;
        width: 80%;
    }
    .single-service-title{
        text-align: center;
        margin-bottom: 25px;
    }
    .content-product{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;

    }
    .content-product-item{
        display: flex;
        align-items: flex-start;
        padding: 30px;
        border: 1px solid grey;
        margin-top: 30px;
        min-width: 100%;
        @media (max-width: 992px) {
            flex-wrap: wrap;
            align-items: first baseline;
            justify-content: center;
        }
    }
    .content-product-item-image{
        display: flex;


        margin-right: 30px;
    }
    .content-product-item-image>img{
        height: 80px;
    }
    .product-item-text{
        max-width: 768px;
        min-width: 300px;
        flex-wrap: wrap;
        display: flex;
        flex-direction: column;
        @media (max-width: 992px) {
            padding-top: 30px;
        }
    }
    .item-text-text,.product-item-price{
        margin-right: 50px;
    }
    .product-item-price{
        min-width: 100px;
        margin-left: auto
    }
    .button-content{
        justify-content: center;
    }
    .btn-button{
        background: #0a53be;
        color: aliceblue;
        margin-right: 70px;
        font-size: 16px;
        text-transform: uppercase;
        border-radius: 6px;
        text-align: center;
        text-decoration: none;
        padding: 8px 30px 10px 30px;}
</style>