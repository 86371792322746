<template>
<div class="snt">
    <div class="container">
        <div class="title">
            <h2 class="snt-title">Сопроводительные
                накладные на товары</h2>
            <p class="snt-subtitle">Настройка «Виртуального склада» в 1С</p>
        </div>
        <div class="snt-content">
            <div class="snt-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
            </svg>
            <p class="snt-content-text">Консультация по подготовке к переходу на СНТ</p>
            </div>

            <div class="snt-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
            </svg>
            <p class="snt-content-text">24/7 принимаем ваши заявки</p>
            </div>
                <div class="snt-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
            </svg>

            <p class="snt-content-text">Консультация по работе с СНТ</p>
                </div>
        </div>
        <div class="snt-price">
            Стоимость: 30 000 тенге
        </div>
        <div class="button-section">
            <myButton @click="showDialog" type="submit" class="btn-button">Заказать настройку СНТ</myButton>
            <router-link

                    :to="{name: 'snt'}"
                    replace
                    custom="true"
                    v-slot="{ navigate, href, route }"
                    >
                <a class="btn-button" :href="route.fullPath"  > Подробнее про СНТ</a>
                </router-link>
        </div>
        <Popup v-model:show="dialogVisible">
            <Form
                    @emailSend="sendForm"
            >
            </Form>
        </Popup>
    </div>
</div>

</template>

<script>
    import {mapGetters,mapMutations} from "vuex"
    import myButton from "@/components/UI/myButton";
    import Popup from "@/components/UI/Popup";
    import Form from "@/components/UI/Form";
    import sectonTitle from "@/components/UI/sectonTitle";
    export default {
        components: {
            myButton, sectonTitle,Popup,Form
        },


        data() {
            return{
                dialogVisible: false,
                callDates: [{
                    name: '',
                    email: '',
                    phone: '',
                    admin_email: '',
                    form_subject: '',
                    project_name: ''
                }],
            }

        },
        props: {
            products: {
                type: Array,
                required: true
            }
        },
        methods: {
            showDialog() {
                this.dialogVisible = true
            },
            sendForm(callData){
                this.callDates.push(callData);
                this.dialogVisible = false;
            },
        },
    }
</script>

<style scoped lang="scss">
.snt{
    display: flex;
    padding-top: 90px;
    background: url("../assets/snt-bg.jpg");
    position: relative;
    padding-bottom: 100px;
    object-position: center;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #00173A;
        opacity: .85;
        z-index: 1;
    }
}
.title{
    display: flex;
    position: relative;
    width: 50%;
    color: white;
    z-index: 10;
    flex-direction: column;
    @media (max-width: 767px) {
        width: 100%;
    }
}
.snt-title{
    font-size: 58px;
    @media (max-width: 767px) {
        font-size: 42px;
    }
    @media (max-width: 420px) {
        font-size: 38px;
    }
    @media (max-width: 378px) {
        font-size: 32px;
    }
}
.snt-subtitle{
    font-size: 28px;
    padding-top: 25px;
    @media (max-width: 378px) {
        font-size: 24px;
    }
}
.snt-content{
    display: flex;
    position: relative;
    justify-content: space-between;
    padding-top: 40px;
    z-index: 10;
    color: white;
    flex-wrap: wrap;
    width: 80%;
    @media (max-width: 767px) {
        width: 100%;
        flex-direction: column;
    }
}
.snt-item{
    display: flex;
    position: relative;
}
.snt-content-text{
    margin-left: 10px;

    @media (max-width: 767px) {
        width: 100%;
    }
}
.snt-price{
    display: flex;
    position: relative;
    z-index: 10;
    color: white;
    font-size: 24px;
    padding-top: 40px;
}
.button-section{
    display: flex;
    position: relative;
    z-index: 10;
    padding-top: 60px;
    @media (max-width: 768px) {
        flex-direction: column;
        padding-top: 40px;
    }
}
.btn-button{
    background: #0a53be;
    color: aliceblue;
    margin-right: 70px;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 6px;
    text-align: center;
    text-decoration: none;
    padding: 8px 30px 10px 30px;
    @media (max-width: 768px) {
        margin-right: 0;
        margin-top: 30px;
    }
}

</style>