<template>
    <div id="sidemenu">
        <button class="sidemenu__btn" type="button" >
            <span class="top"></span>
            <span class="mid"></span>
            <span class="bottom"></span>
        </button>

    </div>
</template>

<script>

    export default {


        data() {

            return{
                navOpen: false,
                selected: '',
                }


            }


    }
</script>

<style scoped lang="scss">
    .active{
        background: #06357a;

    }
    #sidemenu {
        display: flex;
        nav {
            width: 100%;
            background: #1e2637;
            position: fixed;
            top: -60px;
            right: 0;
            opacity: .3;
            z-index: 1000;
            height: 100%;
            overflow: auto;
            bottom: 0;
            left: 0;}
        }

        // box-shadow: 2px 0 3px$grey-6;



        .sidemenu {
            &__btn {
                display: block;
                width: 50px;
                height: 50px;
                background: none;
                border: none;
                position: relative;
                z-index: 100;
                appearance: none;
                cursor: pointer;
                outline: none;
                top: 2px;
                @media (min-width: 769px) {
                    display: flex;
                }
                @media (max-width: 768px) {
                    display: flex;
                }


                span {
                    display: block;
                    width: 20px;
                    height: 2px;
                    margin: auto;
                    background: black;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    transition: all .8s ease;

                    &.top {
                        transform: translateY(-8px);
                    }

                    &.bottom {
                        transform: translateY(8px);
                    }
                }
                &.active{
                    .top {
                        transform: rotate(-45deg);
                    }
                    .mid{
                        transform: translateX(-20px) rotate(360deg);
                        opacity: 0;
                    }
                    .bottom {
                        transform: rotate(45deg);
                    }
                }

            }
            &__wrapper {
                padding-top: 60px;
                margin-bottom: 50px;
                overflow: auto;

            }

           }



    .translateX-enter{
        transform:translateX(-200px);
        opacity: 0;
    }

    .translateX-enter-active,.translateX-leave-active{
        transform-origin: top left 0;
        transition:.8s ease;
    }

    .translateX-leave-to{
        transform: translateX(-200px);
        opacity: 0;
    }

    @media (max-width: 1199px) {
        #sidemenu{
            display: flex;

        }
    }
    @media (max-width: 991.98px) {
        #sidemenu{
            display: flex;

        }
    }

    @media (max-width: 767.98px) {
        #sidemenu{
            display: flex;
            margin-right: 20px;
        }
    }

    @media (max-width: 374.98px) {
        #sidemenu {

            margin-right: 20px;
        }
    }

</style>