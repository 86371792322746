<template>
    <select v-model="modelValue" @change="changeOptions">
        <option disabled value="">Выберите из списка</option>
        <option
                v-for="option in options"
                :key="option.value"
                :value="option.value"
                @click="selectOption(option)"
        >{{option.value}}</option>
    </select>
</template>

<script>
    export default {
    props:{
        modelValue: {
            type: String,
        },
        options: {
            type: Array,
            default: () => [],
        }
    },
        methods: {
            changeOptions(event){
                this.$emit('update: modelValue', event.target.value)

            },
            selectOption(option){
                this.$emit('selectBY', option)


            }
        }
    }
</script>

<style scoped>

</style>