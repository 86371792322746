<template>
    <div class="header-main">
        <div class="container">
            <div class="title">
                <div class="title-inner">
                    <h4 class="small-title">АВТОМАТИЗАЦИЯ БИЗНЕСА</h4>
                    <h1 class="header-title">
                        ПРОДАЖА И ВНЕДРЕНИЕ ПРОГРАММ 1С</h1>
                    <hr class="header-line">
                    <div class="title-text">

                        <p >Выведи свой бизнес на новый уровень развития</p>
                    </div>

                    <div class="block-button">
                        <my-button @click="showDialog" type="submit" class="btn-button">Получить консультацию</my-button>
                    </div>

                </div>




            

            </div>



            <Popup v-model:show="dialogVisible">
                <Form
                        @emailSend="sendForm"
                >
                </Form>
            </Popup>
        </div>
    </div>

</template>

<script>
    import {mapGetters,mapMutations} from "vuex"
    import myButton from "@/components/UI/myButton";
    import Popup from "@/components/UI/Popup";
    import Form from "@/components/UI/Form";
    import sectonTitle from "@/components/UI/sectonTitle";
    export default {
        components: {
            myButton, sectonTitle,Popup,Form
        },

        data() {
            return{
                dialogVisible: false,
                callDates: [{
                    name: '',
                    email: '',
                    phone: '',
                    admin_email: '',
                    form_subject: '',
                    project_name: ''
                }],
            }

        },
        props: {
            products: {
                type: Array,
                required: true
            }
        },
        methods: {
            showDialog() {
                this.dialogVisible = true
            },
            sendForm(callData){
                this.callDates.push(callData);
                this.dialogVisible = false;
            },

        },

        computed:{
            ...mapGetters(['GET_TITLE', 'LIST_PRODUCT']),

        },
    }
</script>

<style scoped lang="scss">
    .header-main{
        background: url("../assets/display-first1.jpg") no-repeat;
        height: 100vh;
        position: relative;
        background-position: center;
        background-size: cover;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #00173A; // #2d4a8a
            opacity: .85;
            z-index: 1;

        }
        @media (min-width: 1200px) {
            background-attachment: fixed;

        }
        @media (max-width: 991.98px) {
            height: auto;

        }
    }
    .title{
        display: flex;
        padding-top: 200px;
        position: relative;
        z-index: 5;
        color: white;
        padding-bottom: 190px;
        justify-content: space-between;
    }
@media (max-width: 374px) {
    .title{
        padding-top: calc(140px + (200 - 140) * ((100vw - 320px) / (1920 - 320)));;
    }
}
    .title-inner{
        display: flex;
        flex-direction: column;
        max-width: 786px;
    }
    .title-inner > *:not(:last-child){
         margin: 0 0 25px 0;
     }
    @media (max-width: 991.98px) {
        .title-inner{
            width: calc(300px + (860 - 300) * ((100vw - 320px) / (1920 - 320)));

        }
    }

    .small-title{
        margin-bottom: 10px !important;
        color: #FFC562;
        font-size: 24px;
        opacity: .8;
        font-weight: 300;
        line-height: 1.4em;
        position: relative;
        letter-spacing: .07em;
        text-transform: uppercase;

        @media (max-width: 1399.98px) {
            font-size: 22px;

        }

        @media (max-width: 1199.98px) {


        }

        @media (max-width: 991.98px) {

            font-size: calc(14px + (24 - 14) * ((100vw - 320px) / (1920 - 320)));
        }



    @media (max-width: 767.98px) {


    }

    @media (max-width: 575.98px) {


    }
    }





    .header-title{
        display: flex;
        align-items: center;
        position: relative;
        color: white;
        opacity: 0.9;
        flex-wrap: wrap;
        font-size: 58px;
        font-weight: bold;
        line-height: 140%;

        @media (max-width: 1399.98px) {

            font-size: 54px;
        }

        @media (max-width: 1199.98px) {


        }

        @media (max-width: 991.98px) {

            font-size: calc(32px + (54 - 32) * ((100vw - 320px) / (1920 - 320)));
        }

        @media (max-width: 767.98px) {


        }

        @media (max-width: 575.98px) {


        }

    }

    .title-text{
        font-size: 25px;
        opacity: 0.9;
        flex-wrap: wrap;
        hyphens: manual;
        position: relative;
        text-transform: uppercase;
        letter-spacing: .07em;

        @media (max-width: 1399.98px) {
            font-size: 23px;

        }

        @media (max-width: 1199.98px) {


        }

        @media (max-width: 991.98px) {

            font-size: calc(14px + (25 - 14) * ((100vw - 320px) / (1920 - 320)));
        }

        @media (max-width: 767.98px) {


        }

        @media (max-width: 575.98px) {


        }

    }

    .header-image{
        display: flex;
        top: -30px;
        position: relative;
        z-index: 5;
    }
    img{
        height: 480px;
        display: none;
    }
    @media (max-width: 1400px) {
        img{
            height: calc(380px + (480 - 380) * ((100vw - 960px) / (1920 - 960)));
        }
    }
    @media (max-width: 1200px) {
        img{
            height: calc(360px + (380 - 360) * ((100vw - 720px) / (1920 - 720)));
        }
    }
    .header-line{
        display: flex;
        position: relative;
        opacity: .6;
        width: 735px;
        height: 3px;
        color: #FFC562;
        z-index: 2445;

        @media (max-width: 1399.98px) {

            width: 690px;
        }

        @media (max-width: 1199.98px) {


        }

        @media (max-width: 991.98px) {
            width: calc(295px + (735 - 295) * ((100vw - 320px) / (1920 - 320)));

        }

        @media (max-width: 767.98px) {


        }

        @media (max-width: 575.98px) {


        }

    }

.header-down-content{
    display: none;
    position: relative;
    color: white;
    margin: auto 0;
    padding: 0;
    z-index: 10;
    font-size: 18px;
    margin-top: 60px;

}
.snt-link{
    flex-basis: 50%;
    display: flex;
    justify-content: space-between;
}
.esf-link{
    flex-basis: 50%;
    display: flex;
    justify-content: space-between;
}
.btn-button{
    @media (min-width: 1200px) {
        &:hover {


        }
    }

}
</style>