<template>
    <div class="container">
        <h2>Автоматизация</h2>
    </div>
</template>

<script>
    export default {

    }
</script>

<style scoped>

</style>