<template>
    <div class="title">
        <h2 >{{selected}} </h2>
    </div>
    <div class="filter-header">
    <CatalogGamburger
            v-on:click="navOpen=!navOpen"
            v-bind:class="{active:navOpen}"

    ></CatalogGamburger>

        <mySelect
                v-model:modelValue="selected"
                       :options="categories"
                        @change="sortBY"
        >
        </mySelect>



</div>
    <div class="wrapper" :class="{'template-columns': navOpen}">

        <transition name="translateX">
        <div class="sidebar" v-show="navOpen" :class="{'sidebar-mobile': navOpen}">
           <div class="sidebar-header">
               <h3>Filter</h3>
               <div class="button-content">

                   <button class="btn-button back" type="button" v-on:click="navOpen=!navOpen" v-bind:class="{active:navOpen}">Вернутся назад</button>
               </div>
           </div>



            <div class="characterName">
            <my-select v-model="selectedLicenceType"
                       :options="licenceType"
                       @change="changeLicenceType"

            >
            </my-select>
            </div>

            <div class="characterName">

                <div class="sort-price">
                    <div class="input-price">
                        <label >от
                            <input class="price" v-model.number="minPrice"  type="number" name=""    >
                        </label>
                        <label >до
                            <input class="price" v-model.number="maxPrice"  type="number" name=""   >
                        </label>
                    </div>

                </div>
                <div class="range-slider">
                    <input class="range" type="range" v-model.number="minPrice" min="0" max="2500000"  step="1000" />
                    <input class="range" type="range" v-model.number="maxPrice" min="0" max="2500000" step="1000" />
                </div>

            </div>

            <div class="characterName">
                <div class="characterValue">
                    <label for="test"  >
                        <input class="" type="checkbox" name="test" id="test" v-model="city" value='Алматы' >Алматы

                    </label>

                </div>

                <div class="characterValue">
                <label for="test2">
                    <input type="checkbox" name="test2" id="test2" v-model.trim="city" value='Астана' >Астана
                </label>
                </div>

                <div class="characterValue">
                <label for="test3">
                    <input type="checkbox" name="test3" id="test3" v-model.trim="city" value='Караганда' >Караганда
                </label>
                </div>

            </div>
            <div class="characterName">

            <div class="characterValue">
                <label >
                    <input class="" type="checkbox" name="test"  v-model="typeOfAccounting" value='Бухгалтерский' >Бухгалтерский
                </label>
            </div>

            <div class="characterValue">
                <label >
                    <input type="checkbox" name="test2"  v-model.trim="typeOfAccounting" value='Управленческий' >Управленческий
                </label>
            </div>

            <div class="characterValue">
                <label >
                    <input type="checkbox" name="test3"  v-model.trim="typeOfAccounting" value='Розничная торговля' >Розничная торговля
                </label>
            </div>
            </div>
            <div class="characterName">
                <div class="characterValue">
                    <label >
                        <input class="" type="checkbox" name="test"  v-model="deliveryType" value='(программная защита)' >программная защита
                    </label>
                </div>

                <div class="characterValue">
                    <label >
                        <input type="checkbox" name="test2"  v-model.trim="deliveryType" value='USB' >USB
                    </label>
                </div>

                <div class="characterValue">
                    <label >
                        <input type="checkbox" name="test3"  v-model.trim="deliveryType" value='Электронная поставка' >Электронная поставка
                    </label>
                </div>
            </div>

            <div class="characterName">
                <div class="double-button">
                    <button class="btn-button" v-on:click="resetOptions">Reset</button>
                    <button class="btn-button" v-on:click="resetOptions">Reset</button>
                </div>

            </div>

        </div>
        </transition>

        <div class="content" :class="{'margin-left-add': navOpen}">
            <div class="content-product">
                <productItemInline
                        v-for="product in filteredItems"
                        :id="product.id"
                        :product="product"
                >
                    <div class="button-content">
                        <my-button @click="showDialog" type="submit" class="btn-button">Заказать</my-button>
                    </div>
                </productItemInline>

            </div>
        </div>
        <Popup v-model:show="dialogVisible">
            <Form
                    @emailSend="sendForm"
            >
            </Form>
        </Popup>
    </div>

</template>

<script>
    import {mapGetters} from "vuex"
    import myButton from "@/components/UI/myButton";
    import mySelect from "@/components/UI/mySelect";
    import Popup from "@/components/UI/Popup";
    import Form from "@/components/UI/Form";
    import CatalogGamburger from "@/components/UI/CatalogGamburger";
    import sectonTitle from "@/components/UI/sectonTitle";
    import productItemInline from "@/components/product-item-inline";
    export default {
        components: {
            myButton, sectonTitle, Popup, Form,productItemInline,CatalogGamburger, mySelect
        },
        props:{
            selectCategory: {
              type: Object
            }
        },
        data() {
            return {
                navOpen: false,
                dialogVisible: false,
                callDates: [{
                    name: '',
                    email: '',
                    phone: '',
                    admin_email: '',
                    form_subject: '',
                    project_name: '',
                }],
                city:
                    [],
                categories:[
                    {name: 'soft', value: 'Программное обеспечение'},
                    {name: 'СОФТ', value: 'СОФТ'},
                    ],
                typeOfAccounting:[],
                conditions:[],
                deliveryType:[],
                licenceType: [
                    {name: 'Типовое', value: 'Типовое решение'},
                    {name: 'Отраслевое', value: 'Отраслевое решение'},
                ],

                minPrice: 0,
                maxPrice: 2500000,
                products:[ ],
                selected: 'СОФТ',
                selectedLicenceType:"",
            }

        },

        computed:{
            ...mapGetters(['LIST_PRODUCT']),
            filteredItems() {
                if (this.products.length){
                return this.products.filter(item => {
                    this.conditions = [true];


                    this.conditions.push(item.price >= this.minPrice && item.price <= this.maxPrice)



                    if (this.typeOfAccounting.length) {
                        this.conditions.push(this.typeOfAccounting.indexOf(item.typeOfAccounting) > -1)
                    }
                    if (this.deliveryType.length) {
                        this.conditions.push(this.deliveryType.indexOf(item.deliveryType) !== -1)
                    }
                    if (this.city.length) {
                        this.conditions.push(this.city.indexOf(item.city) > -1)
                    }

                    return this.conditions.every(condition => condition)

                })
                }



            },
        },

        methods: {
            sortBY (category) {
                    this.resetOptions()
                    let products = this.LIST_PRODUCT
                    let vm = this
                    this.selected = category.target.value
                    return vm.products = products.filter(pr => pr.category === category.target.value);

            },
            changeLicenceType(licenceType){
                let products = this.products
                this.selectedLicenceType = licenceType.target.value
                if (products.length){
                    return this.products = products.filter(pr => pr.licenceType === licenceType.target.value)
                }

                else return this.products = this.LIST_PRODUCT.filter(pr => pr.category === this.selected && pr.licenceType === this.selectedLicenceType)


            },


            showDialog() {
                this.dialogVisible = true
            },
            sendForm(callData) {
                this.callDates.push(callData);
                this.dialogVisible = false;
            },





            uniqueCheck(e) {
                this.city = [];
                if (e.target.checked) {
                    this.city.push(e.target.value);
                }
            },

            resetOptions() {
                    this.selectedLicenceType = 'this.modelValue'



            },
        },

        mounted(){
            this.selected = this.selectCategory
            return this.products = this.LIST_PRODUCT.filter(pr => pr.category === this.selectCategory)

        }
    }
</script>

<style scoped lang="scss">
    .products{
        padding-top: 200px;
    }
    .wrapper{
        display: grid;

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
        }
    }
    .template-columns{
        grid-template-columns: 1fr 3fr;
    }
    .margin-left-add{
        margin-left: 20px;
    }
    .filter-header{
        display: flex;
        background: #cccccc;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        margin-bottom: 20px;
    }
    .sidebar{
        background: #5B8EDA;
        margin-bottom: 90px;
        border-radius: 12px;
        padding-bottom: 90px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        @media (max-width: 768px) {
            display: none;
        }
    }
    .sidebar-mobile{
        @media (max-width: 768px) {
        display: flex;
        position: fixed;
        z-index: 100;
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;
        overflow: auto;
    }}
    .sidebar-header{
        display: flex;
        margin: 20px;
        @media (max-width: 768px) {
            justify-content: space-between;
        }
    }
    .characterName{
        display: flex;
        flex-direction: column;
        margin: 10px 20px;
        border-bottom: 2px solid aliceblue;
        padding-bottom: 10px;
    }

    .characterValue{
        display: flex;
        flex-direction: column;
    }

    .sort-price{
        display: flex;
        flex-direction: column;
    }
    input[type=checkbox]{
        margin-right: 8px;
    }
    .input-price{
        display: flex;

    }
    .price{
        width: calc(100% - 5px);
        padding-left: 8px;
        margin-top: 8px;
    }
    .range-slider{
        padding-top: 40px;
        width: 260px;
        outline: 0;
        text-align: center;
        position: relative;
    }
    .range-slider svg, .range-slider input[type=range]{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 270px;
        outline: 0;
        display: flex;
    }
    .range::-webkit-slider-thumb{
        z-index: 5;
        position: relative;
        top: 2px;
        display: flex;
        margin-top: -7px;
        border: none;
    }
    .range[type=range]::-moz-range-thumb{
        z-index: 5;
        position: relative;
        top: 2px;
        display: flex;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -7px;
    }
    .content{

        position: relative;
    }


    .content-product{
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
        display: flex;
        padding-bottom: 60px;


        @media (max-width: 1920px) {
            margin-right: 0;
        }
        @media (max-width: 768px) {
            margin: 0 auto;
        }
    }

    .double-button{
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
    }
    .button-content{
        justify-content: center;
    }
    .btn-button{
        background: #0a53be;
        color: aliceblue;
        font-size: 16px;
        text-transform: uppercase;
        border-radius: 6px;
        text-align: center;
        text-decoration: none;
        padding: 8px 30px 10px 30px;}
    .back{
        display: none;
        @media (max-width: 768px) {
            display: flex;

        }
    }
    .translateX-enter{
        transform:translateX(-200px);
        opacity: 0;
        transition:.2s ease;
    }

    .translateX-enter-active,.translateX-leave-active{
        transform-origin: top left 0;
        transition:.2s ease;
    }

    .translateX-leave-to{
        transform: translateX(-200px);
        opacity: 0;
    }
</style>