<template>
    <div class="App">

        <headerContent/>
        <PopularService></PopularService>
        <callBack></callBack>
        <productList


        />

        <Support/>

        <Review></Review>
        <News></News>
        <vHr></vHr>

    </div>
</template>

<script>
    import {mapGetters,mapMutations} from "vuex"
    import productList from "@/components/ProductList";
    import sectonTitle from "@/components/UI/sectonTitle";
    import headerContent from "@/components/header";
    import callBack from "@/components/callBack";
    import myButton from "@/components/UI/myButton";
    import PopularService from "@/components/popularService";
    import SingleService from "@/components/single-service";
    import Support from "@/components/Support-service";
    import vHr from "@/components/v-hr";
    import News from "@/components/news";
    import Review from "@/components/review";
    export default {


        components: {
            headerContent, productList,SingleService,Support,myButton,callBack,PopularService,vHr, News,Review,sectonTitle
        },
        computed:{
            ...mapGetters(['LIST_PRODUCT']),
          Post: function () {
                return this.LIST_PRODUCT.slice(0,8)
          }
        },


    }
</script>

<style lang="scss">
.App{
    flex: 1 1 auto;
}
</style>