<template>
    <div class="products">
    <div class="container ">


        <catalog :selectCategory="category"></catalog>
    </div>
    </div>
</template>

<script>
    import {mapGetters,mapMutations} from "vuex"
    import myButton from "@/components/UI/myButton";
    import Popup from "@/components/UI/Popup";
    import Form from "@/components/UI/Form";
    import catalog from "@/components/catalog";
    import CatalogGamburger from "@/components/UI/CatalogGamburger";
    import sectonTitle from "@/components/UI/sectonTitle";
export default {
    components: {
        myButton, sectonTitle, Popup, Form,catalog,
    },
    data(){
        return{
            category: ''
        }

    },


    computed:{

        ...mapGetters(['LIST_PRODUCT']),

    },
    created()  {
        let category = this.LIST_PRODUCT.find(item => item.category === this.$route.params.category)
        if (category){
              this.category = category.category.toString()
        }
    },

}
</script>

<style scoped lang="scss">
.products{
    padding-top: 200px;
}
</style>