<template>
    <div class="Contacts-page">
    <div class="contacts-content">
        <div class="map" ref="map"></div>
    </div>
        <div class="container">

    </div>
    </div>

</template>

<script>
    export default {
        created() {
            ymaps.ready(this.initMap);
        },
        data: () => ({
            coords: [ 43.236073, 76.907996 ],
        }),
        methods: {
            initMap() {
                this.map = new ymaps.Map(this.$refs.map, {
                    center: this.coords,
                    zoom: 16,
                });

                this.marker = new ymaps.Placemark(this.coords, {
                    hintContent: 'AMB',
                });

                this.map.geoObjects.add(this.marker);

                this.map.events.add('click', this.onMapClick);

                this.$watch('coords', this.updateMap);
            },
            onMapClick(e) {
                this.coords = e.get('coords');
            },
            updateMap() {
                this.map.panTo(this.coords);
                this.marker.geometry.setCoordinates(this.coords);
            },
        },
    }
</script>

<style lang="scss">
.Contacts-page{
    display: flex;
    background: #cccccc;
}
.contacts-content{
    display: flex;
    position: relative;
    padding-top: 90px;
}
.contents{
    display: flex;
    justify-content: space-between;
}
.contacts{
    display: flex;

    width: 500px;
}

.map{
    display: flex;
    position: relative;
    height: 70vh;
    width: 100vw;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: black;
        opacity: .65;
        z-index: 10;
    }
}
</style>