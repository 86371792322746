<template>

        <div class="header">
            <div class="container">

                    <navbar/>


            </div>


        </div>

            <router-view></router-view>




            <Footer/>



            <FooterBottom></FooterBottom>

    <Call></Call>
    <Callmobile></Callmobile>

</template>

<script>

    import topline from "@/components/topLine";
    import Navbar from "@/components/Navbar";
    import Callmobile from "@/components/UI/callmobile";
    import Call from "@/components/UI/call";
    import Footer from "@/components/footer";
    import FooterBottom from "@/components/footer-bottom";
    export default {
        components: {
         topline,Navbar,Footer,FooterBottom,Callmobile,Call
        },


    }
</script>

<style lang="scss">



* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
    position: relative;
}

.container{
    width: 1400px;
    margin: 0 auto;
    padding: 0 15px;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
    overflow-x: hidden;
    position: relative;
    @media (max-width: 1399px) {
        width: 1399px;
    }
    @media (max-width: 1199px) {
        width: 1199px;
    }

    @media (max-width: 991.98px) {
        width: 991.98px;
    }

    @media (max-width: 767.98px) {
        width: 767.98px;

    }

    @media (max-width: 575.98px) {
        width: 545.98px;
        box-sizing: border-box;
    }
    @media (max-width: 544.98px) {
        width: 495.98px;
        box-sizing: border-box;
    }
    @media (max-width: 494.98px) {
        width: 455.98px;
        box-sizing: border-box;
    }
    @media (max-width: 454.98px) {
        width: 415.98px;
        box-sizing: border-box;
    }
    @media (max-width: 414.98px) {
        width: 375.98px;
        box-sizing: border-box;
    }
    @media (max-width: 374.98px) {
        width: 330.98px;
        box-sizing: border-box;
    }
}

button:active, button:focus {
        outline: none !important;
        border: 0 !important;
}
button::-moz-focus-inner {
        border: 0 !important;
}
body{
    min-width: 320px;
    position: relative;
    overflow-x: hidden;
    height: 100%;

}



.header{
    display: flex;
    box-shadow: 0 0 10px #cccccc;
    min-width: 100%;
    position: fixed;
    height: 95px;
    background: #09003C;
    z-index: 100;

}


</style>