<template>
    <div class="container">
        <div class="single-service">
            <h2 class="single-service-title"></h2>
            <p class="service-text"></p>
        </div>
        <div class="single-service-inner">
            <div class="single-servise-item"
                 v-for="service in LIST_SERVICE">
                <div class="single-service-image">
                    <img src="../assets/3.svg" alt="3">
                </div>


                <div class="service-item-text"


                >
                    <h5 class="item-title">{{service.title}}</h5>
                    <div class="discription">
                        <div class="text-discription">
                            <p class="text disc">{{service.discription}}</p>
                        </div>


                            <p class="text price">от {{service.price}} тг/ч</p>


                        <div>
                            <button @click="showDialog" type="submit" class="button">Заказать</button>
                        </div>

                    </div>
                </div>
            </div>

            <Popup v-model:show="dialogVisible">
                <Form
                        @emailSend="sendForm"
                >
                </Form>
            </Popup>
        </div>
    </div>
</template>

<script>
    import {mapGetters,mapMutations} from "vuex"
    import myButton from "@/components/UI/myButton";
    import Popup from "@/components/UI/Popup";
    import Form from "@/components/UI/Form";
    import sectonTitle from "@/components/UI/sectonTitle";
    export default {
        components: {
            myButton, sectonTitle,Popup,Form
        },

        data() {
            return{
                dialogVisible: false,
                callDates: [{
                    name: '',
                    email: '',
                    phone: '',
                    admin_email: '',
                    form_subject: '',
                    project_name: ''
                }],
            }

        },

        methods: {
            showDialog() {
                this.dialogVisible = true
            },
            sendForm(callData){
                this.callDates.push(callData);
                this.dialogVisible = false;
            },

        },
        computed:{
            ...mapGetters(['GET_TITLE', 'LIST_SERVICE']),

        },
    }
</script>

<style scoped lang="scss">

    .single-service-inner{
        padding-top: 70px;
        display: flex;
        flex-direction: column;
        padding-bottom: 70px;
    }

    @media (max-width: 600px) {
        .single-service-inner{

        }

    }
    .single-servise-item{
        margin-bottom: 40px;
        padding: 25px;
        display: flex;
        border-radius: 12px;
        min-width: 100%;
        justify-content: space-between;
        box-shadow: 0 0 10px #cccccc
    }

    @media (max-width: 1400px) {
        .single-servise-item {
            min-width: auto;
            margin: 15px;
        }
    }



    .single-service-image{
        display: flex;
        margin-right: 20px;
        border-right: 1px solid #242424 ;
        padding-right: 15px;
        align-self: center;
    }
    img{
        height: 80px;
    }
    .service-item-text{
        display: flex;
        flex-direction: column;
        color: #242424;
    }
    .item-title{
        font-size: 20px;
        font-weight: bold;
        padding-top: 10px;
        letter-spacing: .07em
    }
    .discription{
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        align-items: center;
    }
   .discription > *:not(:last-child){

        margin-bottom: 0;
        align-items: center;

    }
   @media (max-width: 1400px) {
       .discription {
           margin-right: calc(10px + (50 - 10) * ((100vw - 720px) / (1920 - 720)));
       }
   }

    @media (max-width: 990px) {
        .discription {
            flex-direction: column;
            margin-right: 0;
        }
    }
   .text{
       padding-top: 8px;
   }
   .text-discription{
      display: flex;
      width: 750px;
      flex-wrap: wrap;
   }

    @media (max-width: 1400px) {
        .text-discription{
            width: auto;
        }
    }
   @media (max-width: 1400px){
       .text{
           margin-right: calc(10px + (50 - 10) * ((100vw - 720px) / (1920 - 720)))!important;
       }
   }
   .disc{
       width: auto;
       display: flex;
       flex-wrap: wrap;

       color: darken(grey,20%);

   }

    .single-servise-item{

    @media (max-width: 960px) {
            flex-direction: column;
            text-align: center;
    }
}
    @media (max-width: 960px) {
        .single-service-image{
            padding-right: 0!important;
            margin-right: 0;
            border: none;
            margin-bottom: 20px;
        }
    }
.price{
    margin-right: 40px;
    @media (max-width: 960px) {

        padding-bottom: 15px;
    }

}

.button{
        padding: 12px 25px 12px 25px !important;
        text-transform: uppercase;
        border-radius: 8px;
        background: #1e2637;
        font-size: 16px;
        color: white;

    }
</style>