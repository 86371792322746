<template>
    <div id="sidemenu">
        <button class="sidemenu__btn" type="button" v-on:click="navOpen=!navOpen" v-bind:class="{active:navOpen}">
            <span class="top"></span>
            <span class="mid"></span>
            <span class="bottom"></span>
        </button>
        <transition name="translateX">
            <nav v-show="navOpen">
                <div class="sidemenu__wrapper">
                    <div class="sidemenu__header">

                        <div class="callback">
                            <my-button @click="showDialog" type="submit" class="button">Обратный звонок</my-button>
                        </div>
                        <div class="sidemenu__contacts"
                             v-for="contact in GET_CONTACTS">
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                                </svg>
                            </div>
                            <a class="sidemenu__phone" href="tel:+77056472999">{{contact.phone}}</a>
                        </div>
                    </div>


                    <ul class="sidemenu__list">
                        <router-link
                                v-for="item in GET_MENU"
                                :key="item.route"
                                :to="{name: item.route}"
                                v-slot="{route, isExactActive, navigate}"
                                :custom="true"
                        >
                            <li class="sidemenu__item" :class="isExactActive ? 'active': ''" v-on:click="navOpen=!navOpen">
                                <a :href="route.fullPath" @click="navigate">{{item.title}}</a>
                            </li>
                        </router-link>
                    </ul>
                </div>
            </nav>
        </transition>
    </div>
    <Popup v-model:show="dialogVisible">
        <Form
                @emailSend="sendForm"
        >
        </Form>
    </Popup>
</template>

<script>
    import {mapGetters,mapMutations} from "vuex"
    import myButton from "@/components/UI/myButton";
    import Popup from "@/components/UI/Popup";
    import Form from "@/components/UI/Form";
    import sectonTitle from "@/components/UI/sectonTitle";

    export default {
        components: {
            myButton, sectonTitle,Popup,Form
        },

        data() {

                return{
                    navOpen: false,
                    dialogVisible: false,
                    callDates: [{
                        name: '',
                        email: '',
                        phone: '',
                        admin_email: '',
                        form_subject: '',
                        project_name: ''
                    }],


            }

        },


        methods: {
            showDialog() {
                this.dialogVisible = true
            },
            sendForm(callData){
                this.callDates.push(callData);
                this.dialogVisible = false;
            },



        },
        computed:{
          ...mapGetters(['GET_MENU']),
          ...mapGetters(['GET_CONTACTS'])
        },

    }
</script>

<style scoped lang="scss">
.active{
    background: #06357a;

}
    #sidemenu {
        display: none;
        nav {
            width: 100%;
        // height: calc(100% - #{$headerHeight} - #{$footerHeight});
            background: #1e2637;
            position: fixed;
            top: 80px;
            right: 0;
            z-index: 99;
            height: 80%;
            overflow: auto;

        // box-shadow: 2px 0 3px$grey-6;

        }

        .sidemenu {
            &__btn {
                display: block;
                width: 50px;
                height: 50px;
                background: none;
                border: none;
                position: relative;
                z-index: 100;
                appearance: none;
                cursor: pointer;
                outline: none;
                top: 2px;



                span {
                    display: block;
                    width: 20px;
                    height: 2px;
                    margin: auto;
                    background: white;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    transition: all .4s ease;

                    &.top {
                        transform: translateY(-8px);
                    }

                    &.bottom {
                        transform: translateY(8px);
                    }
                }
                &.active{
                    .top {
                        transform: rotate(-45deg);
                    }
                    .mid{
                        transform: translateX(-20px) rotate(360deg);
                        opacity: 0;
                    }
                    .bottom {
                        transform: rotate(45deg);
                    }
                }

            }
        &__wrapper {
            padding-top: 60px;
            margin-bottom: 50px;
            overflow: auto;

            }
            &__header{
                display: flex;
                align-items: center;
                flex-direction: column;

            }
                &__contacts{
                    color: white;
                    padding-top: 25px;
                    padding-bottom: 25px;
                    display: flex;
                }
                    &__phone{
                        color: white;
                }
            &__list {

                list-style:none;
                padding: 0;
                margin: 0 ;

            }

            &__item {
                left: 0;
                top: 0;
                right: 0;
                margin: 0 auto;
                a {
                    text-decoration: none;
                    line-height: 1.6em;
                    font-size: 1.6em;
                    justify-content: center;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    display: flex;
                    color: white;
                    transition: .4s ease;

                    &:hover {
                        background: lightgrey;
                        color: dimgrey;
                    }
                    &:active{
                        background: royalblue;
                    }
                }
            }
        }
    }

    .translateX-enter{
        transform:translateX(-200px);
        opacity: 0;
    }

    .translateX-enter-active,.translateX-leave-active{
        transform-origin: top left 0;
        transition:.2s ease;
    }

    .translateX-leave-to{
        transform: translateX(-200px);
        opacity: 0;
    }

@media (max-width: 1199px) {
        #sidemenu{
            display: flex;

        }
}
@media (max-width: 991.98px) {
    #sidemenu{
        display: flex;

    }
}

@media (max-width: 767.98px) {
    #sidemenu{
        display: flex;
        margin-right: 20px;
    }
}

@media (max-width: 374.98px) {
    #sidemenu {

        margin-right: 20px;
    }
}
.icon{
    margin-right: 10px;
}
</style>