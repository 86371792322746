<template>
   <div class="p-service">
         <div class="container">
            <div class="p-service-title">
               <h2>Обновите свой бизнес</h2>
               <p>Специализируемся на разработке и внедрении 1С,
                  сопровождении 1С, интеграций и сложных систем для автоматизации бизнес-процессов.</p>
            </div>

            <div class="p-service-content">

            <div class="p-service-item">
               <div class="p-service-content-image">
                  <img src="../assets/automation.jpg" alt="">
               </div>
               <div class="p-service-text">
                  <a href="">Автоматизация розничной торговли</a>
               </div>
            </div>

            <div class="p-service-item">
               <div class="p-service-content-image">
                  <img src="../assets/Pharma.jpg" alt="">
               </div>
               <div class="p-service-text">
                  <a href="">Автоматизация аптек</a>
               </div>
            </div>

            <div class="p-service-item">
                  <div class="p-service-content-image">
                     <img src="../assets/Sclad.jpg" alt="">
                  </div>
                  <div class="p-service-text">
                     <a href="">Автоматизация склада</a>
                  </div>
               </div>

            <div class="p-service-item">
                  <div class="p-service-content-image">
                     <img src="../assets/buh.jpg" alt="">
                  </div>
                  <div class="p-service-text">
                     <a href="">Автоматизация бухгалтерского учета</a>
                  </div>
               </div>

            <div class="p-service-item">
                  <div class="p-service-content-image">
                     <img src="../assets/butik.jpg" alt="">
                  </div>
                  <div class="p-service-text">
                     <a href="">Автоматизация бутиков</a>
                  </div>
               </div>

            <div class="p-service-item">
               <div class="p-service-content-image">
                  <img src="../assets/Building.jpg" alt="">
               </div>
               <div class="p-service-text">
                  <a href="#">Автоматизация строительных компаний</a>
               </div>
            </div>

            </div>

           <div class="form-row">

           </div>
         </div>
   </div>
</template>

<script>
    import {mapGetters,mapMutations} from "vuex"
    import myButton from "@/components/UI/myButton";
    import Popup from "@/components/UI/Popup";
    import Form from "@/components/UI/Form";
    import sectonTitle from "@/components/UI/sectonTitle";
    export default {
        components: {
            myButton, sectonTitle,Popup,Form
        },


        data() {
            return {
                dialogVisible: false,
                callData: {
                    name: '',
                    email: '',
                    phone: '',
                    admin_email: 'gglu@amb-it.kz',
                    form_subject: 'Заявка',
                    project_name: 'AMB'
                },

            }
        },
        props: {
            show: {
                type: Boolean,
                default: false
            }
        },



        methods: {

            emailSend() {
                this.$emit('emailSend')
                const params = new URLSearchParams();
                params.append('name', this.callData.name);
                params.append('email', this.callData.email);
                params.append('phone', this.callData.phone);
                params.append('project_name', this.callData.project_name);
                params.append('admin_email', this.callData.admin_email);
                params.append('form_subject', this.callData.form_subject);

                axios.post(
                    "/mail.php",
                    params, {
                        headers: {
                            'content-type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(function (responce) {
                    console.log('SUCCESS!!');


                })
                    .catch(function () {
                        console.log('FAILURE!!');

                    });


            },

        }




    }
</script>

<style scoped lang="scss">

   .p-service{
      display: flex;
      padding-bottom: 80px;
      position: relative;
   }

   .p-service-title{
      display: flex;
      align-items: center;
      padding-top: 80px;
      margin-bottom: 60px;
      flex-direction: column;
      justify-content: center;
      text-align: center;
   }
   .p-service-content{
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      position: relative;
      flex-wrap: wrap;
   }
   .p-service-item{
      display: flex;
      flex-direction: column;
      width: calc(33% - 10px);
      margin-bottom: 20px;
      @media (max-width: 992px){
         width: calc(50% - 10px);
         text-align: center;
      }
      @media (max-width: 576px){
         width: calc(100%);
      }
   }
   .p-service-content-image{
      display: flex;
      min-width: min-content;
      position: relative;
      &:before {
         content: '';
         position: absolute;
         top: 0;
         left: 0;
         right: 0;
         bottom: 0;
         background: #00173A; // #2d4a8a
         opacity: .70;
         z-index: 1;
         border-radius: 14px;
      }
   }
   img{
      height: 240px;
      position: relative;
      width: 100%;
      border-radius: 14px;
      object-fit: cover;
      object-position: center;
   }
   .p-service-text{
      position: absolute;
      align-items: center;
      justify-content: center;
      display: flex;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 5;
   }
   .p-service-text>a{
      text-decoration: none;
      cursor: pointer;
      color: white;
      align-items: center;
   }

   .form-row{
      display: flex;
      position: relative;
   }
   .form-body{
      display: flex;
      position: relative;
      width: 100%;
      @media (max-width: 768px){
         flex-wrap: wrap;
      }
   }
   form{
      margin: 20px auto;
      width: 100%;
   }
   .input-content{
      margin-right: 20px;
      display: flex;
      position: relative;
      width: 100%;
      @media (max-width: 768px){
         margin-right: 0;
         padding-top: 25px;
      }
   }
   input{
      width: 100%;
      height: 35px;
      border: 2px solid;
      padding-right: 40px;
      flex-shrink: 0;
       padding-left: 10px;
      box-sizing: border-box;

   }
   .button-content{
      display: flex;
      padding-top: 35px;
      flex-shrink: 0;
      justify-content: center;
   }

   .button{
       padding: 12px 30px 12px 30px !important;
       text-transform: uppercase;
       border-radius: 8px;
       background: #0a53be;
       font-size: 16px;
       color: white;
       justify-content: center;
   }
   .button:hover{
       background: #1e2637;
       opacity: 0.9;
       color: white;
   }
</style>