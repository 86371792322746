<template>
    <div class="logo">

        <router-link to="/">AMB</router-link>

    </div>
</template>

<script>
    export default {

    }
</script>

<style>
.logo{
    display: block;
    align-self: center;
    margin-right: 50px;
    font-size: 48px;
    position: relative;
    margin-bottom: 8px;
    font-weight: bold;

}
.logo>a{
    color: white;
    text-decoration: none;
    font-weight: bold;
}
.router-link-active{
    color: white;
    text-decoration: none;
    font-weight: bold;
}
.router-link-active:hover{
     color: white;
 }
@media (max-width: 1200px) {
    .logo{
        margin-right: 30px;
    }
}


</style>